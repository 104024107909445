import { FormControlType, SelectOption } from 'components/Elements';

import { Organization } from './organization.interface';

export enum ParticipantAccessType {
    CONTRACTING_ENTITY = 'Contracting Entity',
    DELEGATE = 'Delegate',
}

export interface ConditionBrackets {
    conditionIds: string[];
}

export interface RulesSetFormData {
    conditions: Rule[];
    brackets: ConditionBrackets[];
}

interface RuleCalculations {
    title: string;
    formula: string;
    qualification: string;
    showOn: (string | SelectOption)[];
    value?: string;
}
export interface ConfigurationRule {
    rule: string;
    name: string;
    claimFileVersion: string;
    rulesFormData: RulesSetFormData;
    ruleCalculations: RuleCalculations[];
    allowPayerOverwrite: boolean;
    id: string;
    parentRuleId?: string;
}

export interface ConfigurationDataField {
    id: string;
    question: boolean;
    label: string;
    type:
        | 'text'
        | 'number'
        | 'date'
        | 'dateTime'
        | 'file'
        | 'singleSelect'
        | 'checkbox'
        | 'checkboxMulti';
    required: boolean;
    displayOnList: boolean;
    options?: { value: string }[];
    enabled: boolean;
}

export enum DataIntakeFeatureType {
    CLAIMS = 'claims',
    FILES_AND_DATA = 'filesAndData',
}

export interface ConfigurationIntakeFeature {
    id: string;
    name: string;
    type: DataIntakeFeatureType;
    enabled: boolean;
    rules?: ConfigurationRule[];
    dataFields?: ConfigurationDataField[];
    monitoringRules?: ConfigurationRule[];
    labelsConfig?: ConfigurationLabelsConfig;
    defaultClaimFileVersion: string;
    claimV2RequiredFields: string[];
    supportingInformation?: {
        enabled: boolean;
    };
    instructions: string;
    showClaimStatusForManufacturer?: boolean;
    ruleClaimFilters: ClaimFilterGroup[];
}

export interface ConfigurationLabelsConfig {
    uploadClaimAttestation?: string;
}

export interface OutcomesReportsMedReviewItem {
    reviewItemId: string;
}

export interface OutcomesReportMedicalReviewerConfig {
    manufacturerReviewRequired: boolean;
    coeusMedReviewRequired: boolean;
    reviewReports: OutcomesReportsMedReviewItem[];
    reviewIntakeFeatures: OutcomesReportsMedReviewItem[];
}

export interface TenantAppConfigurationInner {
    configName: string;
    startDate: string;
    endDate: string;
    intakeFeatures: ConfigurationIntakeFeature[];
    outcomesReportMedicalReviewerConfig: OutcomesReportMedicalReviewerConfig;
}

export interface OutcomesReportConfig {
    validTransfusionCodes: string; // comma separated string of codes
}

export interface CustomReportConfig {
    reportId: string;
    enabled: boolean;
    forPayers: boolean;
    manufacturerReview: boolean;
}

export interface TenantAppConfigurationMetadata {
    dataFieldsCount: number;
    appId: number;
    tenantId: string;
}

export type CustomFieldValue = Record<string, string | boolean | string[]>;

export interface ParticipantProviderConfig {
    providerSubscribed: boolean;
    providerDataFields: {
        [dataIntakeFeatureId: string]: {
            dataFields: {
                dataFieldId: string;
            }[];
        };
    };
}

interface PayerVBCRules {
    [payerId: string]: {
        intakeFeatures: ConfigurationIntakeFeature[];
    };
}

interface ContractDate {
    startDate: string; // ISO format date string
    endDate: string; // ISO format date string
}

export interface ParticipantConfiguration {
    claimFileVersion: Record<string, string>;
    monitoringRules: Record<string, ConfigurationRule[] | boolean | undefined>;
    designations: Record<string, string[]>;
    customField1: CustomFieldValue;
    customField2: CustomFieldValue;
    customField3: CustomFieldValue;
    newClaimWebForm: Record<string, boolean>;
    providerConfig: Record<string, ParticipantProviderConfig>;
    vbcRules: PayerVBCRules;
    contractDates: Record<string, ContractDate>;
}

export interface ParticipantCustomFieldConfig {
    label: string;
    type: 'text' | 'number' | 'date' | 'dateTime' | 'singleSelect' | 'checkbox' | 'checkboxMulti';
    required?: boolean;
    options?: { value: string }[];
    enabled: boolean;
}

export interface TenantAppConfiguration {
    coverage_verification_required: boolean;
    vbc_agreement: string;
    vbc_agreement_timestamp: string;
    detectContractBreach: boolean;
    monitoringRules: boolean;
    primaryPolicyCustomerReviewRequired: boolean;
    primaryPolicyCoeusMedAffairsReviewRequired: boolean;
    metadata: TenantAppConfigurationMetadata;
    configurations: TenantAppConfigurationInner[];
    outcomesReportConfiguration: OutcomesReportConfig;
    customReportsConfiguration: CustomReportConfig[];
    healthPlanConfiguration: HealthPlanConfiguration;
    participantConfiguration: ParticipantConfiguration;
    participantDesignationsCustom: string[];
    participantCustomFieldsConfiguration: {
        customField1: ParticipantCustomFieldConfig;
        customField2: ParticipantCustomFieldConfig;
        customField3: ParticipantCustomFieldConfig;
    };
    participantDesignationsCustomFieldLabel: string;
}

export interface HealthPlanConfiguration {
    lineOfBusinessRequired: boolean;
    lineOfBusiness: string[];
    customerApprovalRequired: boolean;
    healthPlanPolicyManufacturerReviewRequired: boolean;
    healthPlanPolicyCoeusMedAffairsReviewRequired: boolean;
}

export interface TenantAppDetail {
    url: string; // VBC Agreement Document URL
    timestamp: string; // VBC Agreement Document URL Timestamp
    config: TenantAppConfiguration;
    id: number;
    tenant_id: string;
    app_name: string;
    custom_name: string;
    app_type: string;
    description: string;
    website: string;
    app_terms_file_name: string;
    app_terms_file_url: string;
    disabled: boolean;
    tenant: Tenant;
}

export interface Tenant {
    tenant_id: string;
    organization_id: number;
    organization: Organization;
}

// This is used for both payer primary policy and health plan policy status
export enum VBCClinicalPolicyStatus {
    EXEMPTED = 'Exempted',
    PENDING = 'Pending',
    APPROVED = 'Approved',
    REJECTED = 'Rejected',
    CLARIFICATION_NEEDED = 'Clarification Needed',
    ALIGNED_WITH_CRITERIA = 'Aligned with Criteria',
    NOT_ALIGNED_WITH_CRITERIA = 'Not Aligned with Criteria',
    APPROVAL_NOT_REQUIRED = 'Approval not required',
}

export interface VBCClinicalPolicyReviewOption {
    status: VBCClinicalPolicyStatus;
    label: string;
    labelDesc: string;
}

export interface VBCClinicalPolicyReviewTableRow {
    reviewer: string;
    reviewedAt?: string;
    status?: VBCClinicalPolicyStatus;
    comment?: string;
}

export interface ClaimFormFieldValidations {
    required?: boolean;
    maxLength?: number;
}

export interface ClaimFormField {
    controlId: string;
    type: FormControlType;
    label: string;
    selectOptions?: SelectOption[];
    validations?: ClaimFormFieldValidations;
    helpText?: string;
    defaultValue?: string;
    //defaultRequired and mustHave is applicable to v2 only
    defaultRequired?: boolean;
    mustHave?: boolean;
}

export interface RuleField {
    controlId?: string;
    type?: FormControlType;
    label?: string;
    selectOptions?: SelectOption[];
    conditionOptions?: SelectOption[];
}

export interface Rule {
    conditionId: string;
    firstFieldClaimNumber: string;
    firstField: string;
    firstFieldHasMultipleValues: boolean;
    firstFieldExtract: string;
    hasSecondField: boolean;
    secondFieldCondition: string;
    secondFieldClaimNumber: string;
    secondField: string;
    secondFieldHasMultipleValues: boolean;
    secondFieldExtract: string;
    overallExtract: string;
    condition: string;
    value: string;
    conditionalOperator: string;
}

export interface RulesFormPayloadData {
    conditions: RulesFormPayload[];
}

export interface RulesFormPayload {
    first_field_claim_number: number;
    first_field: string;
    first_field_has_multiple_values: boolean;
    first_field_extract: string;
    has_second_field: boolean;
    second_field_condition: string;
    second_field_claim_number: number;
    second_field: string;
    second_field_has_multiple_values: boolean;
    second_field_extract: string;
    overall_extract: string;
    condition: string;
    value: string | number;
    conditional_operator: string;
}

export interface ClaimFormSection {
    sectionLabel: string;
    fields: ClaimFormField[];
    helpText?: string;
}

export interface OutcomesReportReviewTableRow {
    reviewer: string;
    reviewedAt?: string;
    status?: string;
    comment?: string;
}

export interface RuleClaimFilter {
    id: string;
    field: string;
    value: string;
}

export interface ClaimFilterGroup {
    claimFileVersion: string;
    filters: RuleClaimFilter[];
}
