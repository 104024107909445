import { SelectOption } from 'components/Elements';
import { ClaimFormSection, ILocalize, RuleField } from 'types';

import { AppConstant } from './app.constants';

export const getSelectOptions = (lineOfBusinessArray: string[] = []) => {
    return [
        ...lineOfBusinessArray.map((value) => ({
            label: value,
            value,
        })),
    ];
};

export const getOptionsWithSelectEmptyOption = (option: string[] = []) => {
    return [
        { label: 'Select', value: '' },
        ...option.map((value) => ({
            label: value,
            value,
        })),
    ];
};

export const extractSelectOptions = (options: SelectOption[] = []): string[] => {
    return options.map((option) => option.value) as string[];
};

export const ClaimsFormV2ConfigSelectOptions = {
    PATIENT_ID_QUALIFIER_OPTIONS: [
        { label: 'Select', value: '' },
        { label: 'Social Security Number', value: '01' },
        { label: 'Facility ID Number (LTC Facility)', value: '1J' },
        { label: "Driver's License Number", value: '02' },
        { label: 'U.S. Military ID', value: '03' },
        {
            label: 'Non-SSN-based patient identifier assigned by health plan',
            value: '04',
        },
        { label: 'SSN-based patient identifier assigned by health plan', value: '05' },
        { label: 'Medicaid ID', value: '06' },
        { label: 'State Issued ID', value: '07' },
        { label: 'Passport ID', value: '08' },
        { label: 'Medicare Beneficiary ID', value: '09' },
        { label: 'Employer Assigned ID', value: '10' },
        { label: 'Payer/PBM Assigned ID', value: '11' },
        { label: 'Alien Number (Government Permanent Residence Number)', value: '12' },
        { label: 'Government Student VISA Number', value: '13' },
        { label: 'Indian Tribal ID', value: '14' },
        { label: 'NCPDP Universal Patient Identifier (UPI)', value: '15' },
        { label: 'LexID Universal Patient Identifier (UPI)', value: '16' },
        { label: 'Appriss Health Patient ID (AHPID)', value: '17' },
        { label: 'Evernorth Universal Patient Identifier (UPI)', value: '18' },
        {
            label: 'Third Party De-identified Patient ID. (If a third party tokenizer is used, the de-identified patient ID and this qualifier must be sent)',
            value: '98',
        },
        { label: 'Other', value: '99' },
        { label: 'Medical Record Identification Number (EHR)', value: 'EA' },
    ],

    PATIENT_GENDER_CODE_OPTIONS: [
        { label: 'Not Specified', value: 0 },
        { label: 'Male', value: 1 },
        { label: 'Female', value: 2 },
        { label: 'Non-Binary', value: 3 },
    ],

    PROVIDER_ID_QUALIFIER_OPTIONS: [
        { label: 'Select', value: '' },
        { label: 'Drug Enforcement Administration (DEA)', value: '01' },
        { label: 'State License', value: '02' },
        { label: 'Social Security Number (SSN)', value: '03' },
        { label: 'Name', value: '04' },
        { label: 'National Provider Identifier (NPI)', value: '05' },
        { label: 'Health Industry Number (HIN)', value: '06' },
        { label: 'State Issued', value: '07' },
        { label: 'Other', value: '99' },
    ],

    DIAGNOSIS_CODE_QUALIFIER_OPTIONS: [
        { label: 'Not Specified', value: '00' },
        { label: 'International Classification of Diseases (ICD-9)', value: '01' },
        {
            label: 'International Classification of Diseases-10-Clinical Modifications(ICD-10-CM)',
            value: '02',
        },
        { label: 'National Criteria Care Institute (NCCI)', value: '03' },
        {
            label: 'The Systematized Nomenclature of Medicine Clinical Terms® (SNOMED®)',
            value: '04',
        },
        { label: 'Current Dental Terminology (CDT)', value: '05' },
        {
            label: 'American Psychiatric Association Diagnostic Statistical Manual of Mental Disorders (DSM-IV)',
            value: '07',
        },
        {
            label: 'American Psychiatric Association Diagnostic Statistical Manual of Mental Disorders (DSM-5)',
            value: '08',
        },
    ],

    PRODUCT_SERVICE_ID_QUALIFIER_OPTIONS: [
        { label: 'Not Specified', value: '00' },
        { label: 'Universal Product Code (UPC)', value: '01' },
        { label: 'Health Related Item (HRI)', value: '02' },
        { label: 'National Drug Code (NDC)', value: '03' },
        {
            label: 'Health Industry Business Communications Council (HIBCC)',
            value: '04',
        },
        { label: 'Department of Defense (DOD)', value: '05' },
        {
            label: 'Drug Use Review/Professional Pharmacy Service (DUR/PPS)',
            value: '06',
        },
        { label: 'Current Procedural Terminology (CPT4)', value: '07' },
        { label: 'Current Procedural Terminology (CPT5)', value: '08' },
        { label: 'Healthcare Common Procedure Coding System (HCPCS)', value: '09' },
        { label: 'Pharmacy Practice Activity Classification (PPAC)', value: '10' },
        {
            label: 'National Pharmaceutical Product Interface Code (NAPPI)',
            value: '11',
        },
        { label: 'Global Trade Identification Number (GTIN)', value: '12' },
        { label: 'Drug Identification Number (DIN)', value: '13' },
        { label: 'First DataBank Formulation ID (GCN)', value: '15' },
        { label: 'First DataBank Medication Name ID (FDB Med Name ID)', value: '28' },
        {
            label: 'First DataBank Routed Medication ID (FDB Routed Med ID)',
            value: '29',
        },
        {
            label: 'First DataBank Routed Dosage Form ID (FDB Routed Dosage Form MedID)',
            value: '30',
        },
        { label: 'First DataBank Medication ID (FDB MedID)', value: '31' },
        {
            label: 'First DataBank Clinical Formulation ID Sequence Number (GCN_SEQNO)',
            value: '32',
        },
        { label: 'First DataBank Ingredient List ID (HICL_SEQNO)', value: '33' },
        { label: 'Universal Product Number (UPN)', value: '34' },
        { label: 'Representative National Drug Code (NDC)', value: '36' },
        {
            label: 'Elsevier/Gold Standard Marketed Product Identifier (MPid)',
            value: '42',
        },
        { label: 'Elsevier/Gold Standard Product Identifier (ProdID)', value: '43' },
        {
            label: 'Elsevier/Gold Standard Specific Product Identifier (SPID)',
            value: '44',
        },
        { label: 'Device Identifier (DI)', value: '45' },
        { label: 'Other', value: '99' },
    ],

    MEASUREMENT_DIMENSION_OPTIONS: [
        { label: 'Blood Pressure (BP)', value: '01' },
        { label: 'Blood Glucose', value: '02' },
        { label: 'Temperature', value: '03' },
        { label: 'Serum Creatinine (SCr)', value: '04' },
        { label: 'Glycosylated Hemoglobin (HbA1c)', value: '05' },
        { label: 'Sodium (Na+)', value: '06' },
        { label: 'Potassium (K+)', value: '07' },
        { label: 'Calcium (Ca++)', value: '08' },
        { label: 'Serum Glutamic-Oxaloacetic Transaminase (SGOT)', value: '09' },
        { label: 'Serum Glutamic-Pyruvic Transaminase (SGPT)', value: '10' },
        { label: 'Alkaline Phosphatase', value: '11' },
        { label: 'Theophylline', value: '12' },
        { label: 'Digoxin', value: '13' },
        { label: 'Weight', value: '14' },
        { label: 'Body Surface Area (BSA)', value: '15' },
        { label: 'Height', value: '16' },
        { label: 'Creatinine Clearance (CrCl)', value: '17' },
        { label: 'Cholesterol', value: '18' },
        { label: 'Low Density Lipoprotein (LDL)', value: '19' },
        { label: 'High Density Lipoprotein (HDL)', value: '20' },
        { label: 'Triglycerides (TG)', value: '21' },
        { label: 'Bone Mineral Density (BMD T-Score)', value: '22' },
        { label: 'Prothrombin Time (PT)', value: '23' },
        { label: 'Hemoglobin (Hb; Hgb)', value: '24' },
        { label: 'Hematocrit (Hct)', value: '25' },
        { label: 'White Blood Cell Count (WBC)', value: '26' },
        { label: 'Red Blood Cell Count (RBC)', value: '27' },
        { label: 'Heart Rate', value: '28' },
        { label: 'Absolute Neutrophil Count (ANC)', value: '29' },
        { label: 'Activated Partial Thromboplastin Time (APTT)', value: '30' },
        { label: 'CD4 Count (also T4 Count, T-helper cells)', value: '31' },
        { label: 'Partial Thromboplastin Time (PTT)', value: '32' },
        { label: 'T-Cell Count', value: '33' },
        { label: 'INR-International Normalized Ratio', value: '34' },
        { label: 'Other', value: '99' },
    ],

    MEASUREMENT_UNIT_OPTIONS: [
        { label: 'Inches (In)', value: '01' },
        { label: 'Centimeters (cm)', value: '02' },
        { label: 'Pounds (lb)', value: '03' },
        { label: 'Kilograms (kg)', value: '04' },
        { label: 'Celsius (C)', value: '05' },
        { label: 'Fahrenheit (F)', value: '06' },
        { label: 'Meters squared (m2)', value: '07' },
        { label: 'Milligrams per deciliter (mg/dl)', value: '08' },
        { label: 'Units per milliliter (U/ml)', value: '09' },
        { label: 'Millimeters of mercury (mmHg)', value: '10' },
        { label: 'Centimeters squared (cm2)', value: '11' },
        { label: 'Milliliters per minute (ml/min)', value: '12' },
        { label: 'Percent (%)', value: '13' },
        { label: 'Milliequivalents per milliliter (mEq/ml)', value: '14' },
        { label: 'International units per liter (IU/L)', value: '15' },
        { label: 'Micrograms per milliliter (mcg/ml)', value: '16' },
        { label: 'Nanograms per milliliter (ng/ml)', value: '17' },
        { label: 'Milligrams per milliliter (mg/ml)', value: '18' },
        { label: 'Ratio', value: '19' },
        { label: 'SI Units', value: '20' },
        { label: 'Millimoles/liter (mmol/l)', value: '21' },
        { label: 'Seconds', value: '22' },
        { label: 'Grams per deciliter (g/dl)', value: '23' },
        { label: 'Cells per cubic millimeter (cells/cu mm)', value: '24' },
        {
            label: '1,000,000 cells per cubic millimeter (million cells/cu mm)',
            value: '25',
        },
        { label: 'Standard deviation', value: '26' },
        { label: 'Beats per minute', value: '27' },
    ],
};
//when updating any key or label language update in V2_RULES_FIELD_CONFIG as well
export const V2_FIELD_KEYS = {
    RECORD_NUMBER: 'record_number',
    CLAIM_SUBMISSION_DATE: 'claim_submission_date',
    LAST_MODIFIED_DATE_AND_TIME: 'last_modified_date_time',

    PATIENT_ID: 'patient_id',
    PATIENT_ID_QUALIFIER: 'patient_id_qualifier',
    PATIENT_LAST_NAME: 'patient_last_name',
    PATIENT_FIRST_NAME: 'patient_first_name',
    PATIENT_GENDER_CODE: 'patient_gender',
    PATIENT_DATE_OF_BIRTH: 'patient_dob',
    PATIENT_ZIP_CODE: 'patient_zip',

    PLAN_ID: 'plan_id',
    PLAN_NAME: 'plan_name',
    LINE_OF_BUSINESS_CODE: 'line_of_business_code',
    MEDICAID_INDICATOR: 'medicaid_indicator',

    CLAIM_SOURCE: 'claim_source',
    PROVIDER_ID: 'provider_id',
    PROVIDER_ID_QUALIFIER: 'provider_id_qualifier',
    PRESCRIBER_ID: 'prescriber_id',
    PRESCRIBER_ID_QUALIFIER: 'prescriber_id_qualifier',
    PRESCRIBER_FIRST_NAME: 'prescriber_first_name',
    PRESCRIBER_LAST_NAME: 'prescriber_last_name',
    PRESCRIBER_STREET_ADDRESS: 'prescriber_street',
    PRESCRIBER_STATE: 'prescriber_state',
    PRESCRIBER_ZIP_CODE: 'prescriber_zip',
    FACILITY_ID: 'facility_id',
    FACILITY_NAME: 'facility_name',
    FACILITY_STATE: 'facility_state',
    FACILITY_STREET_ADDRESS: 'facility_street',
    FACILITY_ZIP_CODE: 'facility_zip',

    DIAGNOSIS_CODE: 'diagnosis_code',
    DIAGNOSIS_CODE_QUALIFIER: 'diagnosis_code_qualifier',
    DIAGNOSIS_CODE_COUNT: 'diagnosis_code_count',

    PROCEDURE_CODE: 'procedure_code',
    PROCEDURE_CODE_MODIFIER: 'procedure_code_modifier',
    PROCEDURE_CODE_COUNT: 'procedure_code_count',
    PRODUCT_SERVICE_ID: 'product_id',
    PRODUCT_SERVICE_ID_QUALIFIER: 'product_id_qualifier',
    PRODUCT_DESCRIPTION: 'product_description',
    DATE_OF_SERVICE: 'date_of_service',
    CLINICAL_INFORMATION_COUNTER: 'clinical_information_counter',
    MEASUREMENT_DATE: 'measurement_date',
    MEASUREMENT_TIME: 'measurement_time',
    MEASUREMENT_VALUE: 'measurement_value',
    MEASUREMENT_DIMENSION: 'measurement_dimension',
    MEASUREMENT_UNIT: 'measurement_unit',

    AUTHORIZATION_NUMBER: 'authorization_number',
    PRIOR_AUTHORIZATION_QUANTITY: 'prior_authorization_quantity',
    PRIOR_AUTHORIZATION_DOLLARS: 'prior_authorization_dollars',
    PRIOR_AUTHORIZATION_QUANTITY_ACCUMULATED: 'prior_authorization_quantity_accumulated',
    PRIOR_AUTHORIZATION_ID_ASSIGNED: 'prior_authorization_id_assigned',

    INGREDIENT_COST_SUBMITTED: 'ingredient_cost_submitted',
    INGREDIENT_COST_PAID: 'ingredient_cost_paid',
    DISPENSING_FEE_SUBMITTED: 'dispensing_fee_submitted',
    PROFESSIONAL_SERVICE_FEE_SUBMITTED: 'professional_service_fee_submitted',
    PATIENT_PAID_AMOUNT_SUBMITTED: 'patient_paid_amount_submitted',
    INCENTIVE_AMOUNT_SUBMITTED: 'incentive_amount_submitted',
    OTHER_AMOUNT_CLAIMED_QUALIFIER: 'other_amount_claimed_qualifier',
    OTHER_AMOUNT_CLAIMED: 'other_amount_claimed',
    REIMBURSEMENT: 'reimbursement',
    BASIS_OF_COST_DETERMINATION: 'basis_of_cost_determination',
    OTHER_PAYER_AMOUNT_PAID: 'other_payer_amount_paid',
    AMOUNT_OF_COPAY: 'amount_of_copay',
    ADJUDICATION_DATE: 'adjudication_date',
    SUBMISSION_CLARIFICATION_CODE: 'submission_clarification_code',
};

//when updating any key or label language update in V2_RULES_FIELD_CONFIG as well
export const V2_FIELD_CONFIG: ClaimFormSection[] = [
    {
        sectionLabel: '',
        fields: [
            {
                controlId: V2_FIELD_KEYS.RECORD_NUMBER,
                type: 'text',
                label: 'Record Number',
                mustHave: true,
                defaultRequired: true,
            },
            {
                controlId: V2_FIELD_KEYS.CLAIM_SUBMISSION_DATE,
                type: 'date',
                label: 'Claim Submission Date',
                defaultRequired: true,
            },
            {
                controlId: V2_FIELD_KEYS.LAST_MODIFIED_DATE_AND_TIME,
                type: 'datetime-local',
                label: 'Last Modified Date and Time',
                defaultRequired: true,
            },
        ],
    },
    {
        sectionLabel: 'Patient Details',
        helpText:
            'The Payer Patient ID is assumed to be the payer/PBM assigned ID. If you are entering a different ID, you will have to select the applicable Patient ID Qualifier value.',
        fields: [
            {
                controlId: V2_FIELD_KEYS.PATIENT_ID,
                type: 'text',
                label: 'Payer Patient ID',
                mustHave: true,
                defaultRequired: true,
            },
            {
                controlId: V2_FIELD_KEYS.PATIENT_ID_QUALIFIER,
                type: 'select',
                label: 'Patient ID Qualifier',
                selectOptions: ClaimsFormV2ConfigSelectOptions.PATIENT_ID_QUALIFIER_OPTIONS,
                defaultRequired: true,
            },
            {
                controlId: V2_FIELD_KEYS.PATIENT_LAST_NAME,
                type: 'text',
                label: 'Patient Last Name',
            },
            {
                controlId: V2_FIELD_KEYS.PATIENT_FIRST_NAME,
                type: 'text',
                label: 'Patient First Name',
            },
            {
                controlId: V2_FIELD_KEYS.PATIENT_GENDER_CODE,
                type: 'select',
                label: 'Patient Gender Code',
                selectOptions: ClaimsFormV2ConfigSelectOptions.PATIENT_GENDER_CODE_OPTIONS,
            },
            {
                controlId: V2_FIELD_KEYS.PATIENT_DATE_OF_BIRTH,
                type: 'date',
                label: 'Patient Date of Birth',
            },
            {
                controlId: V2_FIELD_KEYS.PATIENT_ZIP_CODE,
                type: 'text',
                label: 'Patient Zip Code',
            },
        ],
    },
    {
        sectionLabel: 'Plan Details',
        fields: [
            {
                controlId: V2_FIELD_KEYS.PLAN_ID,
                type: 'text',
                label: 'Plan ID',
                defaultRequired: true,
            },
            {
                controlId: V2_FIELD_KEYS.PLAN_NAME,
                type: 'text',
                label: 'Plan Name',
                mustHave: true,
                defaultRequired: true,
            },
            {
                controlId: V2_FIELD_KEYS.LINE_OF_BUSINESS_CODE,
                type: 'select',
                label: 'Line of Business Code',
                selectOptions: [
                    { label: 'Select', value: '' },
                    ...getSelectOptions(AppConstant.LINE_OF_BUSINESS_VALUES),
                ],
                defaultRequired: true,
            },
            {
                controlId: V2_FIELD_KEYS.MEDICAID_INDICATOR,
                type: 'text',
                label: 'Medicaid Indicator',
            },
        ],
    },
    {
        sectionLabel: 'Provider Details',
        fields: [
            {
                controlId: V2_FIELD_KEYS.CLAIM_SOURCE,
                type: 'text',
                label: 'Claim Source',
            },
            {
                controlId: V2_FIELD_KEYS.PROVIDER_ID,
                type: 'text',
                label: 'Provider ID',
                defaultRequired: true,
            },
            {
                controlId: V2_FIELD_KEYS.PROVIDER_ID_QUALIFIER,
                type: 'select',
                label: 'Provider ID Qualifier',
                selectOptions: ClaimsFormV2ConfigSelectOptions.PROVIDER_ID_QUALIFIER_OPTIONS,
                defaultRequired: true,
            },
            {
                controlId: V2_FIELD_KEYS.PRESCRIBER_ID,
                type: 'text',
                label: 'Prescriber ID',
                defaultRequired: true,
            },
            {
                controlId: V2_FIELD_KEYS.PRESCRIBER_ID_QUALIFIER,
                type: 'select',
                label: 'Prescriber ID Qualifier',
                selectOptions: ClaimsFormV2ConfigSelectOptions.PROVIDER_ID_QUALIFIER_OPTIONS,
                defaultRequired: true,
            },
            {
                controlId: V2_FIELD_KEYS.PRESCRIBER_FIRST_NAME,
                type: 'text',
                label: 'Prescriber First Name',
                defaultRequired: true,
            },
            {
                controlId: V2_FIELD_KEYS.PRESCRIBER_LAST_NAME,
                type: 'text',
                label: 'Prescriber Last Name',
                defaultRequired: true,
            },
            {
                controlId: V2_FIELD_KEYS.PRESCRIBER_STREET_ADDRESS,
                type: 'text',
                label: 'Prescriber Street Address',
                defaultRequired: true,
            },
            {
                controlId: V2_FIELD_KEYS.PRESCRIBER_STATE,
                type: 'text',
                label: 'Prescriber State',
                defaultRequired: true,
            },
            {
                controlId: V2_FIELD_KEYS.PRESCRIBER_ZIP_CODE,
                type: 'text',
                label: 'Prescriber Zip Code',
                defaultRequired: true,
            },
            {
                controlId: V2_FIELD_KEYS.FACILITY_ID,
                type: 'text',
                label: 'Facility ID',
            },
            {
                controlId: V2_FIELD_KEYS.FACILITY_NAME,
                type: 'text',
                label: 'Facility Name',
            },
            {
                controlId: V2_FIELD_KEYS.FACILITY_STATE,
                type: 'text',
                label: 'Facility State',
            },
            {
                controlId: V2_FIELD_KEYS.FACILITY_STREET_ADDRESS,
                type: 'text',
                label: 'Facility Street Address',
            },
            {
                controlId: V2_FIELD_KEYS.FACILITY_ZIP_CODE,
                type: 'text',
                label: 'Facility Zip Code',
            },
        ],
    },
    {
        sectionLabel: 'Diagnosis Details',
        fields: [
            {
                controlId: V2_FIELD_KEYS.DIAGNOSIS_CODE,
                type: 'text',
                label: 'Diagnosis Code',
                defaultRequired: true,
            },
            {
                controlId: V2_FIELD_KEYS.DIAGNOSIS_CODE_QUALIFIER,
                type: 'text',
                label: 'Diagnosis Code Qualifier',
                selectOptions: ClaimsFormV2ConfigSelectOptions.DIAGNOSIS_CODE_QUALIFIER_OPTIONS,
                defaultRequired: true,
            },
        ],
    },
    {
        sectionLabel: 'Treatment Details',
        fields: [
            {
                controlId: V2_FIELD_KEYS.PROCEDURE_CODE,
                type: 'text',
                label: 'Procedure Code',
                defaultRequired: true,
            },
            {
                controlId: V2_FIELD_KEYS.PROCEDURE_CODE_MODIFIER,
                type: 'text',
                label: 'Procedure Code Modifier',
                defaultRequired: true,
            },
            {
                controlId: V2_FIELD_KEYS.PRODUCT_SERVICE_ID,
                type: 'text',
                label: 'Product/Service ID',
                defaultRequired: true,
            },
            {
                controlId: V2_FIELD_KEYS.PRODUCT_SERVICE_ID_QUALIFIER,
                type: 'select',
                label: 'Product Service ID Qualifier',
                selectOptions: ClaimsFormV2ConfigSelectOptions.PRODUCT_SERVICE_ID_QUALIFIER_OPTIONS,
                defaultRequired: true,
            },
            {
                controlId: V2_FIELD_KEYS.PRODUCT_DESCRIPTION,
                type: 'text',
                label: 'Product Description',
            },
            {
                controlId: V2_FIELD_KEYS.DATE_OF_SERVICE,
                type: 'date',
                label: 'Date of Service',
                mustHave: true,
                defaultRequired: true,
            },
            {
                controlId: V2_FIELD_KEYS.MEASUREMENT_DATE,
                type: 'text',
                label: 'Measurement Date',
            },
            {
                controlId: V2_FIELD_KEYS.MEASUREMENT_TIME,
                type: 'text',
                label: 'Measurement Time',
            },
            {
                controlId: V2_FIELD_KEYS.MEASUREMENT_VALUE,
                type: 'text',
                label: 'Measurement Value',
            },
            {
                controlId: V2_FIELD_KEYS.MEASUREMENT_DIMENSION,
                type: 'text',
                label: 'Measurement Dimension',

                selectOptions: ClaimsFormV2ConfigSelectOptions.MEASUREMENT_DIMENSION_OPTIONS,
            },
            {
                controlId: V2_FIELD_KEYS.MEASUREMENT_UNIT,
                type: 'text',
                label: 'Measurement Unit',

                selectOptions: ClaimsFormV2ConfigSelectOptions.MEASUREMENT_UNIT_OPTIONS,
            },
        ],
    },
    {
        sectionLabel: 'Prior Authorization Details',
        fields: [
            {
                controlId: V2_FIELD_KEYS.AUTHORIZATION_NUMBER,
                type: 'text',
                label: 'Authorization Number',
            },
            {
                controlId: V2_FIELD_KEYS.PRIOR_AUTHORIZATION_QUANTITY,
                type: 'number',
                label: 'Prior Authorization Quantity',
            },
            {
                controlId: V2_FIELD_KEYS.PRIOR_AUTHORIZATION_DOLLARS,
                type: 'number',
                label: 'Prior Authorization Dollars Authorized',
            },
            {
                controlId: V2_FIELD_KEYS.PRIOR_AUTHORIZATION_QUANTITY_ACCUMULATED,
                type: 'number',
                label: 'Prior Authorization Quantity Accumulated',
            },
            {
                controlId: V2_FIELD_KEYS.PRIOR_AUTHORIZATION_ID_ASSIGNED,
                type: 'text',
                label: 'Prior Authorization ID Assigned',
            },
        ],
    },
    {
        sectionLabel: 'Reimbursement/Cost Details',
        fields: [
            {
                controlId: V2_FIELD_KEYS.INGREDIENT_COST_SUBMITTED,
                type: 'number',
                label: 'Ingredient Cost Submitted',
            },
            {
                controlId: V2_FIELD_KEYS.INGREDIENT_COST_PAID,
                type: 'number',
                label: 'Ingredient Cost Paid',
            },
            {
                controlId: V2_FIELD_KEYS.DISPENSING_FEE_SUBMITTED,
                type: 'number',
                label: 'Dispensing Fee Submitted',
            },
            {
                controlId: V2_FIELD_KEYS.PROFESSIONAL_SERVICE_FEE_SUBMITTED,
                type: 'number',
                label: 'Professional Service Fee Submitted',
            },
            {
                controlId: V2_FIELD_KEYS.PATIENT_PAID_AMOUNT_SUBMITTED,
                type: 'number',
                label: 'Patient Paid Amount Submitted',
            },
            {
                controlId: V2_FIELD_KEYS.INCENTIVE_AMOUNT_SUBMITTED,
                type: 'number',
                label: 'Incentive Amount Submitted',
            },
            {
                controlId: V2_FIELD_KEYS.OTHER_AMOUNT_CLAIMED_QUALIFIER,
                type: 'text',
                label: 'Other Amount Claimed Submitted Qualifier',
            },
            {
                controlId: V2_FIELD_KEYS.OTHER_AMOUNT_CLAIMED,
                type: 'number',
                label: 'Other Amount Claimed Submitted',
            },
            {
                controlId: V2_FIELD_KEYS.REIMBURSEMENT,
                type: 'number',
                label: 'Reimbursement',
            },
            {
                controlId: V2_FIELD_KEYS.BASIS_OF_COST_DETERMINATION,
                type: 'text',
                label: 'Basis of Cost Determination',
            },
            {
                controlId: V2_FIELD_KEYS.OTHER_PAYER_AMOUNT_PAID,
                type: 'number',
                label: 'Other Payer Amount Paid',
            },
            {
                controlId: V2_FIELD_KEYS.AMOUNT_OF_COPAY,
                type: 'number',
                label: 'Amount of Copay',
            },
            {
                controlId: V2_FIELD_KEYS.ADJUDICATION_DATE,
                type: 'date',
                label: 'Adjudication Date',
            },
            {
                controlId: V2_FIELD_KEYS.SUBMISSION_CLARIFICATION_CODE,
                type: 'number',
                label: 'Submission Clarification Code',
            },
        ],
    },
];

export const getDefaultRequiredControlIds = (): string[] => {
    return V2_FIELD_CONFIG.flatMap(({ fields }) =>
        fields.filter(({ defaultRequired }) => defaultRequired).map(({ controlId }) => controlId)
    );
};

export const CONDITIONS = [
    'Equal to',
    'Not equal to',
    'Greater than',
    'Less than',
    'Add',
    'Difference',
    'Greater than or equal to',
    'Less than or equal to',
    'After',
    'Before',
    'On or after',
    'On or before',
    'In',
];

export const V2_RULES_FIELD_CONFIG: RuleField[] = [
    {
        controlId: V2_FIELD_KEYS.RECORD_NUMBER,
        type: 'text',
        label: 'Record Number',
    },
    {
        controlId: V2_FIELD_KEYS.CLAIM_SUBMISSION_DATE,
        type: 'date',
        label: 'Claim Submission Date',
    },
    {
        controlId: V2_FIELD_KEYS.LAST_MODIFIED_DATE_AND_TIME,
        type: 'datetime-local',
        label: 'Last Modified Date and Time',
    },
    {
        controlId: V2_FIELD_KEYS.PATIENT_ID,
        type: 'text',
        label: 'Payer Patient ID',
    },
    {
        controlId: V2_FIELD_KEYS.PATIENT_ID_QUALIFIER,
        type: 'select',
        label: 'Patient ID Qualifier',
        selectOptions: ClaimsFormV2ConfigSelectOptions.PATIENT_ID_QUALIFIER_OPTIONS,
    },
    {
        controlId: V2_FIELD_KEYS.PATIENT_LAST_NAME,
        type: 'text',
        label: 'Patient Last Name',
    },
    {
        controlId: V2_FIELD_KEYS.PATIENT_FIRST_NAME,
        type: 'text',
        label: 'Patient First Name',
    },
    {
        controlId: V2_FIELD_KEYS.PATIENT_GENDER_CODE,
        type: 'select',
        label: 'Patient Gender Code',
        selectOptions: ClaimsFormV2ConfigSelectOptions.PATIENT_GENDER_CODE_OPTIONS,
    },
    {
        controlId: V2_FIELD_KEYS.PATIENT_DATE_OF_BIRTH,
        type: 'date',
        label: 'Patient Date of Birth',
    },
    {
        controlId: V2_FIELD_KEYS.PATIENT_ZIP_CODE,
        type: 'number',
        label: 'Patient Zip Code',
    },
    {
        controlId: V2_FIELD_KEYS.PLAN_ID,
        type: 'text',
        label: 'Plan ID',
    },
    {
        controlId: V2_FIELD_KEYS.PLAN_NAME,
        type: 'text',
        label: 'Plan Name',
    },
    {
        controlId: V2_FIELD_KEYS.LINE_OF_BUSINESS_CODE,
        type: 'select',
        label: 'Line of Business Code',
        selectOptions: [
            { label: 'Select', value: '' },
            ...getSelectOptions(AppConstant.LINE_OF_BUSINESS_VALUES),
        ],
    },
    {
        controlId: V2_FIELD_KEYS.MEDICAID_INDICATOR,
        type: 'text',
        label: 'Medicaid Indicator',
    },
    {
        controlId: V2_FIELD_KEYS.CLAIM_SOURCE,
        type: 'text',
        label: 'Claim Source',
    },
    {
        controlId: V2_FIELD_KEYS.PROVIDER_ID,
        type: 'text',
        label: 'Provider ID',
    },
    {
        controlId: V2_FIELD_KEYS.PROVIDER_ID_QUALIFIER,
        type: 'select',
        label: 'Provider ID Qualifier',
        selectOptions: ClaimsFormV2ConfigSelectOptions.PROVIDER_ID_QUALIFIER_OPTIONS,
    },
    {
        controlId: V2_FIELD_KEYS.PRESCRIBER_ID,
        type: 'text',
        label: 'Prescriber ID',
    },
    {
        controlId: V2_FIELD_KEYS.PRESCRIBER_ID_QUALIFIER,
        type: 'select',
        label: 'Prescriber ID Qualifier',
        selectOptions: ClaimsFormV2ConfigSelectOptions.PROVIDER_ID_QUALIFIER_OPTIONS,
    },
    {
        controlId: V2_FIELD_KEYS.PRESCRIBER_FIRST_NAME,
        type: 'text',
        label: 'Prescriber First Name',
    },
    {
        controlId: V2_FIELD_KEYS.PRESCRIBER_LAST_NAME,
        type: 'text',
        label: 'Prescriber Last Name',
    },
    {
        controlId: V2_FIELD_KEYS.PRESCRIBER_STREET_ADDRESS,
        type: 'text',
        label: 'Prescriber Street Address',
    },
    {
        controlId: V2_FIELD_KEYS.PRESCRIBER_STATE,
        type: 'text',
        label: 'Prescriber State',
    },
    {
        controlId: V2_FIELD_KEYS.PRESCRIBER_ZIP_CODE,
        type: 'text',
        label: 'Prescriber Zip Code',
    },
    {
        controlId: V2_FIELD_KEYS.FACILITY_ID,
        type: 'text',
        label: 'Facility ID',
    },
    {
        controlId: V2_FIELD_KEYS.FACILITY_NAME,
        type: 'text',
        label: 'Facility Name',
    },
    {
        controlId: V2_FIELD_KEYS.FACILITY_STATE,
        type: 'text',
        label: 'Facility State',
    },
    {
        controlId: V2_FIELD_KEYS.FACILITY_STREET_ADDRESS,
        type: 'text',
        label: 'Facility Street Address',
    },
    {
        controlId: V2_FIELD_KEYS.FACILITY_ZIP_CODE,
        type: 'text',
        label: 'Facility Zip Code',
    },
    {
        controlId: V2_FIELD_KEYS.DIAGNOSIS_CODE,
        type: 'text',
        label: 'Diagnosis Code',
    },
    {
        controlId: V2_FIELD_KEYS.DIAGNOSIS_CODE_QUALIFIER,
        type: 'text',
        label: 'Diagnosis Code Qualifier',
        selectOptions: ClaimsFormV2ConfigSelectOptions.DIAGNOSIS_CODE_QUALIFIER_OPTIONS,
    },
    {
        controlId: V2_FIELD_KEYS.DIAGNOSIS_CODE_COUNT,
        type: 'number',
        label: 'Diagnosis Code Count',
    },
    {
        controlId: V2_FIELD_KEYS.PROCEDURE_CODE,
        type: 'text',
        label: 'Procedure Code',
    },
    {
        controlId: V2_FIELD_KEYS.PROCEDURE_CODE_MODIFIER,
        type: 'text',
        label: 'Procedure Code Modifier',
    },
    {
        controlId: V2_FIELD_KEYS.PROCEDURE_CODE_COUNT,
        type: 'number',
        label: 'Procedure Code Count',
    },
    {
        controlId: V2_FIELD_KEYS.PRODUCT_SERVICE_ID,
        type: 'text',
        label: 'Product/Service ID',
    },
    {
        controlId: V2_FIELD_KEYS.PRODUCT_SERVICE_ID_QUALIFIER,
        type: 'select',
        label: 'Product Service ID Qualifier',
        selectOptions: ClaimsFormV2ConfigSelectOptions.PRODUCT_SERVICE_ID_QUALIFIER_OPTIONS,
    },
    {
        controlId: V2_FIELD_KEYS.PRODUCT_DESCRIPTION,
        type: 'text',
        label: 'Product Description',
    },
    {
        controlId: V2_FIELD_KEYS.DATE_OF_SERVICE,
        type: 'date',
        label: 'Date of Service',
    },
    {
        controlId: V2_FIELD_KEYS.CLINICAL_INFORMATION_COUNTER,
        type: 'number',
        label: 'Clinical Information Counter',
    },
    {
        controlId: V2_FIELD_KEYS.MEASUREMENT_DATE,
        type: 'date',
        label: 'Measurement Date',
    },
    {
        controlId: V2_FIELD_KEYS.MEASUREMENT_TIME,
        type: 'datetime-local',
        label: 'Measurement Time',
    },
    {
        controlId: V2_FIELD_KEYS.MEASUREMENT_VALUE,
        type: 'text',
        label: 'Measurement Value',
    },
    {
        controlId: V2_FIELD_KEYS.MEASUREMENT_DIMENSION,
        type: 'text',
        label: 'Measurement Dimension',
        selectOptions: ClaimsFormV2ConfigSelectOptions.MEASUREMENT_DIMENSION_OPTIONS,
    },
    {
        controlId: V2_FIELD_KEYS.MEASUREMENT_UNIT,
        type: 'text',
        label: 'Measurement Unit',
        selectOptions: ClaimsFormV2ConfigSelectOptions.MEASUREMENT_UNIT_OPTIONS,
    },
    {
        controlId: V2_FIELD_KEYS.AUTHORIZATION_NUMBER,
        type: 'text',
        label: 'Authorization Number',
    },
    {
        controlId: V2_FIELD_KEYS.PRIOR_AUTHORIZATION_QUANTITY,
        type: 'number',
        label: 'Prior Authorization Quantity',
    },
    {
        controlId: V2_FIELD_KEYS.PRIOR_AUTHORIZATION_DOLLARS,
        type: 'number',
        label: 'Prior Authorization Dollars Authorized',
    },
    {
        controlId: V2_FIELD_KEYS.PRIOR_AUTHORIZATION_QUANTITY_ACCUMULATED,
        type: 'number',
        label: 'Prior Authorization Quantity Accumulated',
    },
    {
        controlId: V2_FIELD_KEYS.PRIOR_AUTHORIZATION_ID_ASSIGNED,
        type: 'text',
        label: 'Prior Authorization ID Assigned',
    },
    {
        controlId: V2_FIELD_KEYS.INGREDIENT_COST_SUBMITTED,
        type: 'number',
        label: 'Ingredient Cost Submitted',
    },
    {
        controlId: V2_FIELD_KEYS.INGREDIENT_COST_PAID,
        type: 'number',
        label: 'Ingredient Cost Paid',
    },
    {
        controlId: V2_FIELD_KEYS.DISPENSING_FEE_SUBMITTED,
        type: 'number',
        label: 'Dispensing Fee Submitted',
    },
    {
        controlId: V2_FIELD_KEYS.PROFESSIONAL_SERVICE_FEE_SUBMITTED,
        type: 'number',
        label: 'Professional Service Fee Submitted',
    },
    {
        controlId: V2_FIELD_KEYS.PATIENT_PAID_AMOUNT_SUBMITTED,
        type: 'number',
        label: 'Patient Paid Amount Submitted',
    },
    {
        controlId: V2_FIELD_KEYS.INCENTIVE_AMOUNT_SUBMITTED,
        type: 'number',
        label: 'Incentive Amount Submitted',
    },
    {
        controlId: V2_FIELD_KEYS.OTHER_AMOUNT_CLAIMED_QUALIFIER,
        type: 'text',
        label: 'Other Amount Claimed Submitted Qualifier',
    },
    {
        controlId: V2_FIELD_KEYS.OTHER_AMOUNT_CLAIMED,
        type: 'number',
        label: 'Other Amount Claimed Submitted',
    },
    {
        controlId: V2_FIELD_KEYS.REIMBURSEMENT,
        type: 'number',
        label: 'Reimbursement',
    },
    {
        controlId: V2_FIELD_KEYS.BASIS_OF_COST_DETERMINATION,
        type: 'text',
        label: 'Basis of Cost Determination',
    },
    {
        controlId: V2_FIELD_KEYS.OTHER_PAYER_AMOUNT_PAID,
        type: 'number',
        label: 'Other Payer Amount Paid',
    },
    {
        controlId: V2_FIELD_KEYS.AMOUNT_OF_COPAY,
        type: 'number',
        label: 'Amount of Copay',
    },
    {
        controlId: V2_FIELD_KEYS.ADJUDICATION_DATE,
        type: 'date',
        label: 'Adjudication Date',
    },
    {
        controlId: V2_FIELD_KEYS.SUBMISSION_CLARIFICATION_CODE,
        type: 'number',
        label: 'Submission Clarification Code',
    },
];

export const getRuleExamples = (t: ILocalize) => [
    t.RULE_ENGINE_RULE_EXAMPLE_1,
    t.RULE_ENGINE_RULE_EXAMPLE_2,
];

export const INSTRUCTIONS_MAX_CHARS = 250;

export const RESOURCE_TYPES = ['Branded', 'Clinical', 'Internal'];
export const INTELLIGENT_WORKSPACE_DOCUMENT_MANAGEMENT_STATUSES = ['Draft', 'Expired'];

export const C1_FIELD_KEYS = {
    PATIENT_ID: 'patient_id',
    HEALTH_PLAN: 'health_plan',
    LINE_OF_BUSINESS: 'line_of_business_code',
    PROVIDER_ID: 'provider_id',
    PROVIDER_ADDRESS: 'provider_address',
    SITE_OF_SERVICE_CODE: 'facility_id',
    NDC: 'product_id',
    CLAIMS_DIAGNOSIS_CODE: 'diagnosis_code',
    CLAIMS_DIAGNOSIS_CODE_QUALIFIER: 'diagnosis_code_qualifier',
    DATE_OF_SERVICE: 'date_of_service',
    PROCEDURE_CODE: 'procedure_code',
};

export const C1_FIELD_CONFIG: ClaimFormSection[] = [
    {
        sectionLabel: '',
        fields: [
            {
                controlId: C1_FIELD_KEYS.PATIENT_ID,
                type: 'text',
                label: 'Payer Patient ID',
            },
            {
                controlId: C1_FIELD_KEYS.HEALTH_PLAN,
                type: 'text',
                label: 'Health Plan Name',
            },
            {
                controlId: C1_FIELD_KEYS.LINE_OF_BUSINESS,
                type: 'text',
                label: 'Line of Business',
            },
        ],
    },
    {
        sectionLabel: '',
        fields: [
            {
                controlId: C1_FIELD_KEYS.PROVIDER_ID,
                type: 'text',
                label: 'Provider ID',
            },
            {
                controlId: C1_FIELD_KEYS.PROVIDER_ADDRESS,
                type: 'text',
                label: 'Provider Address',
            },
            {
                controlId: C1_FIELD_KEYS.SITE_OF_SERVICE_CODE,
                type: 'text',
                label: 'Site of Service Code',
            },
            {
                controlId: C1_FIELD_KEYS.NDC,
                type: 'text',
                label: 'NDC',
            },
            {
                controlId: C1_FIELD_KEYS.CLAIMS_DIAGNOSIS_CODE,
                type: 'text',
                label: 'Claims Diagnosis Code',
            },
            {
                controlId: C1_FIELD_KEYS.CLAIMS_DIAGNOSIS_CODE_QUALIFIER,
                type: 'text',
                label: 'Claims Diagnosis Code Qualifier',
            },
            {
                controlId: C1_FIELD_KEYS.PROCEDURE_CODE,
                type: 'text',
                label: 'Procedure Code',
            },
            {
                controlId: C1_FIELD_KEYS.DATE_OF_SERVICE,
                type: 'date',
                label: 'Date of Service',
            },
        ],
    },
];

export const V1_FIELD_KEYS = {
    PATIENT_ID: 'patient_id',
    PATIENT_LAST_NAME: 'patient_last_name',
    PATIENT_FIRST_NAME: 'patient_first_name',
    PATIENT_GENDER: 'patient_gender',
    PATIENT_DOB: 'patient_dob',
    PATIENT_ZIP5: 'patient_zip5',
    DATA_SOURCE: 'data_source',
    RECORD_SEQUENCE: 'record_sequence',
    RECORD_NUMBER: 'record_number',
    RESTATEMENT_FLAG: 'restatement_flag',
    CLIENT_ID: 'client_id',
    ICN: 'icn',
    CLAIM_LINE_NO: 'claim_line_no',
    CLAIM_STATUS: 'claim_status',
    YRQTR: 'yrqtr',
    NDC: 'ndc',
    UNITS: 'units',
    PAID_AMT: 'paid_amt',
    BILLED_AMT: 'billed_amt',
    PROVIDER_ID: 'provider_id',
    PRESCRIB_PROV: 'prescrib_prov',
    PRESC_FIRST_NAME: 'presc_first_name',
    PRESC_LAST_NAME: 'presc_last_name',
    PRESC_EMAIL: 'presc_email',
    PRESC_PHONE: 'presc_phone',
    DATE_OF_SERVICE: 'date_of_service',
    ORIGINAL_PROVIDER_ID: 'original_provider_id',
    PROVIDER_FINAL_PRICE_TYPE_CD: 'provider_final_price_type_cd',
    RECIPIENT_ID: 'recipient_id',
    RX_NBR: 'rx_nbr',
    PROC_CODE: 'proc_code',
    TPL_AMT: 'tpl_amt',
    COPAY_AMT: 'copay_amt',
    PAID_DATE: 'paid_date',
    RECEIVED_DATE: 'received_date',
    DAW: 'daw',
    COMPOUND_IND: 'compound_ind',
    EXCLUSION_RSN: 'exclusion_rsn',
    DAYS_SUPPLY: 'days_supply',
    SOURCE_CD: 'source_cd',
    DISPENSE_FEE: 'dispense_fee',
    REFILL_CD: 'refill_cd',
    INVOICE_YRQTR: 'invoice_yrqtr',
    PROGRAM_ID: 'program_id',
    GROUP_ID: 'group_id',
    FUND_CODE: 'fund_code',
    TOWN_CODE: 'town_code',
    LOCATION_CODE: 'location_code',
    VERSION_RELEASE_NO: 'version_release_no',
    MAGI_INDICATOR: 'magi_indicator',
    BASIS_OF_COST: 'basis_of_cost',
    SUB_CLARIF_CODE: 'sub_clarif_code',
    UD_MODIFIER: 'ud_modifier',
    JG_MODIFIER: 'jg_modifier',
    TB_MODIFIER: 'tb_modifier',
    AY_MODIFIER: 'ay_modifier',
    CLAIM_TYPE: 'claim_type',
    NDC_DESCRIPTION: 'ndc_description',
    PRIMARY_DIAGNOSIS_CODE: 'primary_diagnosis_code',
    SECONDARY_DIAGNOSIS_CODE: 'secondary_diagnosis_code',
    PAYER_NAME: 'payer_name',
    PLAN_NAME: 'plan_name',
    DATE_ADMINISTRATION: 'date_administration',
    BENEFIT_NBR: 'benefit_nbr',
    DRUG_COST_AMT: 'drug_cost_amt',
    FORMULARY_DRUG_CD: 'formulary_drug_cd',
    INTERNAL_CLAIM_UID: 'internal_claim_uid',
    INTERNAL_BATCH_NUMBER: 'internal_batch_number',
    INTERNAL_PATIENT_UID: 'internal_patient_uid',
    DATAVANT_PATIENT_ID: 'datavant_patient_id',
};

export const V1_FIELD_CONFIG: ClaimFormSection[] = [
    {
        sectionLabel: 'Patient Details',
        fields: [
            { controlId: V1_FIELD_KEYS.PATIENT_ID, type: 'text', label: 'Patient Id' },
            {
                controlId: V1_FIELD_KEYS.PATIENT_LAST_NAME,
                type: 'text',
                label: 'Patient Last Name',
            },
            {
                controlId: V1_FIELD_KEYS.PATIENT_FIRST_NAME,
                type: 'text',
                label: 'Patient First Name',
            },
            { controlId: V1_FIELD_KEYS.PATIENT_GENDER, type: 'text', label: 'Patient Gender' },
            { controlId: V1_FIELD_KEYS.PATIENT_DOB, type: 'date', label: 'Patient Date Of Birth' },
            { controlId: V1_FIELD_KEYS.PATIENT_ZIP5, type: 'text', label: 'Patient Zip' },
        ],
    },
    {
        sectionLabel: '',
        fields: [
            { controlId: V1_FIELD_KEYS.DATA_SOURCE, type: 'text', label: 'Data Source' },
            { controlId: V1_FIELD_KEYS.RECORD_SEQUENCE, type: 'text', label: 'Record Sequence' },
            { controlId: V1_FIELD_KEYS.RECORD_NUMBER, type: 'text', label: 'Record Number' },
            { controlId: V1_FIELD_KEYS.RESTATEMENT_FLAG, type: 'text', label: 'Restatement Flag' },
            {
                controlId: V1_FIELD_KEYS.DATAVANT_PATIENT_ID,
                type: 'text',
                label: 'Datavant Patient ID',
            },
            {
                controlId: V1_FIELD_KEYS.INTERNAL_PATIENT_UID,
                type: 'text',
                label: 'Internal Patient UID',
            },
            {
                controlId: V1_FIELD_KEYS.INTERNAL_CLAIM_UID,
                type: 'text',
                label: 'Internal Claim UID',
            },
            {
                controlId: V1_FIELD_KEYS.INTERNAL_BATCH_NUMBER,
                type: 'text',
                label: 'Internal Batch Number',
            },
            { controlId: V1_FIELD_KEYS.CLIENT_ID, type: 'number', label: 'Client Id' },
            { controlId: V1_FIELD_KEYS.ICN, type: 'text', label: 'ICN' },
            { controlId: V1_FIELD_KEYS.CLAIM_LINE_NO, type: 'number', label: 'Claim Line No' },
            { controlId: V1_FIELD_KEYS.CLAIM_STATUS, type: 'text', label: 'Claim Status' },
            { controlId: V1_FIELD_KEYS.YRQTR, type: 'text', label: 'YRQTR' },
            { controlId: V1_FIELD_KEYS.NDC, type: 'text', label: 'NDC' },
            { controlId: V1_FIELD_KEYS.UNITS, type: 'number', label: 'Units' },
            { controlId: V1_FIELD_KEYS.PAID_AMT, type: 'number', label: 'Paid Amount' },
            { controlId: V1_FIELD_KEYS.BILLED_AMT, type: 'number', label: 'Billed Amount' },
            { controlId: V1_FIELD_KEYS.PROVIDER_ID, type: 'text', label: 'Provider Id' },
            { controlId: V1_FIELD_KEYS.PRESCRIB_PROV, type: 'text', label: 'Prescriber Provider' },
            { controlId: V1_FIELD_KEYS.DATE_OF_SERVICE, type: 'date', label: 'Date Of Service' },
            { controlId: V1_FIELD_KEYS.RECIPIENT_ID, type: 'text', label: 'Recipient Id' },
            { controlId: V1_FIELD_KEYS.RX_NBR, type: 'text', label: 'Rx NBR' },
            { controlId: V1_FIELD_KEYS.PROC_CODE, type: 'text', label: 'Proc Code' },
            { controlId: V1_FIELD_KEYS.TPL_AMT, type: 'number', label: 'TPL Amount' },
            { controlId: V1_FIELD_KEYS.COPAY_AMT, type: 'number', label: 'Copay Amount' },
            { controlId: V1_FIELD_KEYS.PAID_DATE, type: 'date', label: 'Paid Date' },
            { controlId: V1_FIELD_KEYS.RECEIVED_DATE, type: 'date', label: 'Received Date' },
            { controlId: V1_FIELD_KEYS.DAW, type: 'text', label: 'DAW' },
            { controlId: V1_FIELD_KEYS.COMPOUND_IND, type: 'text', label: 'Compound Ind' },
            { controlId: V1_FIELD_KEYS.EXCLUSION_RSN, type: 'text', label: 'Exclusion RSN' },
            { controlId: V1_FIELD_KEYS.DAYS_SUPPLY, type: 'number', label: 'Days Supply' },
            { controlId: V1_FIELD_KEYS.SOURCE_CD, type: 'text', label: 'Source CD' },
            { controlId: V1_FIELD_KEYS.DISPENSE_FEE, type: 'number', label: 'Dispense Fee' },
            { controlId: V1_FIELD_KEYS.REFILL_CD, type: 'text', label: 'Refill CD' },
            { controlId: V1_FIELD_KEYS.INVOICE_YRQTR, type: 'text', label: 'Invoice YRQTR' },
            { controlId: V1_FIELD_KEYS.PROGRAM_ID, type: 'text', label: 'Program Id' },
            { controlId: V1_FIELD_KEYS.GROUP_ID, type: 'text', label: 'Group Id' },
            { controlId: V1_FIELD_KEYS.FUND_CODE, type: 'text', label: 'Fund Code' },
            { controlId: V1_FIELD_KEYS.TOWN_CODE, type: 'text', label: 'Town Code' },
            { controlId: V1_FIELD_KEYS.LOCATION_CODE, type: 'text', label: 'Location Code' },
            {
                controlId: V1_FIELD_KEYS.ORIGINAL_PROVIDER_ID,
                type: 'text',
                label: 'Original Provider Id',
            },
            {
                controlId: V1_FIELD_KEYS.PROVIDER_FINAL_PRICE_TYPE_CD,
                type: 'text',
                label: 'Provider Final Price Type Cd',
            },
            {
                controlId: V1_FIELD_KEYS.VERSION_RELEASE_NO,
                type: 'text',
                label: 'Version Release No.',
            },
            { controlId: V1_FIELD_KEYS.MAGI_INDICATOR, type: 'text', label: 'Magi Indicator' },
            { controlId: V1_FIELD_KEYS.BASIS_OF_COST, type: 'text', label: 'Basis Of Cost' },
            {
                controlId: V1_FIELD_KEYS.SUB_CLARIF_CODE,
                type: 'text',
                label: 'Sub Clarif Code',
            },
            { controlId: V1_FIELD_KEYS.UD_MODIFIER, type: 'text', label: 'UD Modifier' },
            { controlId: V1_FIELD_KEYS.JG_MODIFIER, type: 'text', label: 'JD Modifier' },
            { controlId: V1_FIELD_KEYS.TB_MODIFIER, type: 'text', label: 'TB Modifier' },
            { controlId: V1_FIELD_KEYS.AY_MODIFIER, type: 'text', label: 'AY Modifier' },
            { controlId: V1_FIELD_KEYS.CLAIM_TYPE, type: 'text', label: 'Claim Type' },
            { controlId: V1_FIELD_KEYS.NDC_DESCRIPTION, type: 'text', label: 'NDC Description' },
            {
                controlId: V1_FIELD_KEYS.PRIMARY_DIAGNOSIS_CODE,
                type: 'text',
                label: 'Primary Diagnosis Code',
            },
            {
                controlId: V1_FIELD_KEYS.SECONDARY_DIAGNOSIS_CODE,
                type: 'text',
                label: 'Secondary Diagnosis Code',
            },
            { controlId: V1_FIELD_KEYS.PAYER_NAME, type: 'text', label: 'Payer Name' },
            { controlId: V1_FIELD_KEYS.PLAN_NAME, type: 'text', label: 'Plan Name' },
            {
                controlId: V1_FIELD_KEYS.DATE_ADMINISTRATION,
                type: 'date',
                label: 'Date Administration',
            },
            { controlId: V1_FIELD_KEYS.BENEFIT_NBR, type: 'text', label: 'Benefit NBR' },
            { controlId: V1_FIELD_KEYS.DRUG_COST_AMT, type: 'number', label: 'Drug Cost Amount' },
            {
                controlId: V1_FIELD_KEYS.FORMULARY_DRUG_CD,
                type: 'text',
                label: 'Formulary Drug Cd',
            },
        ],
    },
    {
        sectionLabel: 'Provider Details',
        fields: [
            {
                controlId: V1_FIELD_KEYS.PRESC_FIRST_NAME,
                type: 'text',
                label: 'Prescriber First Name',
            },
            {
                controlId: V1_FIELD_KEYS.PRESC_LAST_NAME,
                type: 'text',
                label: 'Prescriber Last Name',
            },
            { controlId: V1_FIELD_KEYS.PRESC_EMAIL, type: 'text', label: 'Prescriber Email' },
            { controlId: V1_FIELD_KEYS.PRESC_PHONE, type: 'number', label: 'Prescriber Phone' },
        ],
    },
];
