import { AppConstant } from 'common';

const windowObj = window as any;

export const getRuntimeEnv = (): string => {
    return windowObj.__RUNTIME_CONFIG__.BE_ENV;
};

export const getBEBaseUrl = (): string => {
    return windowObj.__RUNTIME_CONFIG__.BE_BASE_URL;
};

export const getPowerBiEnvConfig = () => {
    return {
        POWER_BI_WORKSPACE_ID: windowObj.__RUNTIME_CONFIG__.POWER_BI_WORKSPACE_ID,
        POWER_BI_SAMPLE_REPORT_ID: windowObj.__RUNTIME_CONFIG__.POWER_BI_SAMPLE_REPORT_ID,
        POWER_BI_VBC_DASHBOARD_REPORT_ID:
            windowObj.__RUNTIME_CONFIG__.POWER_BI_VBC_DASHBOARD_REPORT_ID,
        SHOW_POWER_BI_DASHBOARD: windowObj.__RUNTIME_CONFIG__.SHOW_POWER_BI_DASHBOARD === 'true',
    };
};

export const getFeatureFlags = (feature: string) => {
    const featureFlagMap = {
        [AppConstant.FEATURE_FLAGS.VBC_MARKETPLACE]:
            windowObj.__RUNTIME_CONFIG__.VBC_MARKETPLACE_FEATURE === 'true', // process.env adds boolean values as string
    };

    return featureFlagMap[feature];
};

export const getCOEBRAStatusPageURL = (): string => {
    return windowObj.__RUNTIME_CONFIG__.COEBRA_STATUS_PAGE_URL;
};

export const getCOEBRAPublicStorageName = (): string => {
    return windowObj.__RUNTIME_CONFIG__.PUBLIC_STORAGE_NAME;
};

export const getCoeusEmailDomain = (): string => {
    return windowObj.__RUNTIME_CONFIG__.COEUS_EMAIL_DOMAIN;
};

export const getCoeusOrgName = (): string => {
    return windowObj.__RUNTIME_CONFIG__.COEUS_ORG_NAME;
};

export const getTechSupportEmail = (): string => {
    return windowObj.__RUNTIME_CONFIG__.TECH_SUPPORT_EMAIL;
};

export const getReportIncidentEmail = (): string => {
    return windowObj.__RUNTIME_CONFIG__.REPORT_INCIDENT_EMAIL;
};

export const getJiraHelpDeskLink = (): string => {
    return windowObj.__RUNTIME_CONFIG__.JIRA_HELP_DESK_LINK;
};
