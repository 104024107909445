import { FC, useEffect, useState } from 'react';
import { NavLink as NavLinkBS } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { NavLink } from 'react-router-dom';

import { AppConstant, PermissionConstants } from 'common';
import { getMyApps } from 'common/api';
import {
    useAppSelector,
    useIsAbleTo,
    useIsCoeusUser,
    useIsPathNameMatching,
    useNavigateToVBCApp,
    useTranslate,
} from 'hooks';
import { dispatch } from 'store';
import { toggleMyAppsAccordion } from 'store/appSettingsSlice';
import { showMyAppsLoading, hideMyAppsLoading } from 'store/loaderSlice';
import { setMyApps } from 'store/platformAppsSlice';
import { PlatformApp } from 'types';
import { showErrorToast } from 'utils/toast';

import { SVGIcon } from '../SVGIcon';

interface MyAppsGrouped {
    customer: string;
    apps: PlatformApp[];
}

export const MyAppList: FC = () => {
    const { t } = useTranslate();
    const [groupedApps, setGroupedApps] = useState<MyAppsGrouped[]>([]);
    const { userAccount } = useAppSelector((state) => state.auth);
    const { myApps, editAppId } = useAppSelector((state) => state.platformApps);
    const { myAppsReload } = useAppSelector((state) => state.reload);
    const { myAppsLoading } = useAppSelector((state) => state.loader);
    const { navigateToVBCApp } = useNavigateToVBCApp();
    const { isPathMatching } = useIsPathNameMatching();
    const isVBCRoute = isPathMatching(AppConstant.ROUTE_PATHS.APPS_VBC);
    const isRebateRoute = isPathMatching(AppConstant.ROUTE_PATHS.APPS_REBATE);
    const isIntelligentWorkspaceRoute = isPathMatching(
        AppConstant.ROUTE_PATHS.APPS_INTELLIGENT_WORKSPACE
    );
    const { appDetails } = useAppSelector((state) => state.vbc);
    const isCoeusUser = useIsCoeusUser();
    const { isAbleTo } = useIsAbleTo();
    const showAppEdit = isCoeusUser && isAbleTo(PermissionConstants.VBC_CONFIGURATION);

    useEffect(() => {
        (async () => {
            try {
                dispatch(showMyAppsLoading());
                const response = await getMyApps();
                dispatch(setMyApps(response));
            } catch {
                showErrorToast(t.MY_APPS_ERROR_MESSAGE);
            } finally {
                dispatch(hideMyAppsLoading());
            }
        })();
    }, [myAppsReload, userAccount]);

    useEffect(() => {
        if (myApps.length) {
            const groupedApps: MyAppsGrouped[] = myApps.reduce(
                (grouped: MyAppsGrouped[], app: PlatformApp) => {
                    const customer = app.tenant.organization.name;
                    const appGroup: MyAppsGrouped | undefined = grouped.find(
                        (appGroup: MyAppsGrouped) => appGroup.customer === customer
                    );
                    if (appGroup) {
                        appGroup.apps.push(app);
                    } else {
                        grouped.push({ customer, apps: [app] });
                    }
                    return grouped;
                },
                []
            );
            const compareCustomers = (a: MyAppsGrouped, b: MyAppsGrouped) =>
                a.customer.localeCompare(b.customer);
            const sortedGroupedApps = groupedApps.sort(compareCustomers);
            setGroupedApps(sortedGroupedApps);
        } else {
            setGroupedApps([]);
        }
    }, [myApps]);

    const handleAppClick = (id: number, tenantId: string, appType: string) => {
        navigateToVBCApp(id, tenantId, appType);
        dispatch(toggleMyAppsAccordion());
    };

    if (myAppsLoading) {
        return (
            <div>
                <Spinner animation="border" variant="primary" />
            </div>
        );
    }

    return (
        <>
            <div className="list-group">
                <div className="nav-menu-item pb-0">
                    <h6>{t.VALUE_BASED_CONTRACTS}</h6>
                </div>
            </div>
            {groupedApps.map(({ customer, apps }, index) => {
                return (
                    <div className="list-group" key={customer}>
                        <div className={`nav-menu-item ${!index && 'border-top-0'}`}>
                            <h6>{customer}</h6>
                            {apps?.map((app) => {
                                return (
                                    <div key={app.id} className="nav-link-action">
                                        <NavLinkBS
                                            as="button"
                                            onClick={() =>
                                                handleAppClick(app.id, app.tenant_id, app.app_name)
                                            }
                                            className={`list-group-item ${
                                                ((isVBCRoute ||
                                                    isRebateRoute ||
                                                    isIntelligentWorkspaceRoute) &&
                                                    appDetails?.custom_name === app.custom_name) ||
                                                editAppId === app.id
                                                    ? 'active-child'
                                                    : ''
                                            }`}
                                        >
                                            {app.custom_name} ({app.app_name})
                                        </NavLinkBS>
                                        {showAppEdit && editAppId !== app.id && (
                                            <NavLink
                                                to={`${AppConstant.ROUTE_PATHS.CUSTOMER_APP_CONFIG}/${app.id}`}
                                                className="list-edit-icon"
                                                reloadDocument
                                            >
                                                <SVGIcon
                                                    icon="EditIcon"
                                                    className="svg-icon ms-0"
                                                />
                                            </NavLink>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </>
    );
};
