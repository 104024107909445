import jwt_decode from 'jwt-decode';

import { DecodedAccessToken, LoginRedirect } from 'features/auth';
import { DocumentReviewParams } from 'types';

interface VBCAppStorageData {
    appId: number;
    appTenantId: number;
    appOrgId: number;
    appName: string;
}

const storagePrefix = 'platform_';

const getStorage = (): Storage => {
    return window.sessionStorage;
};

const storage = {
    getToken: (): string => {
        return getStorage().getItem(`${storagePrefix}token`) as string;
    },
    setToken: (token: string) => {
        getStorage().setItem(`${storagePrefix}token`, token);
    },
    clearToken: () => {
        getStorage().removeItem(`${storagePrefix}token`);
    },
    getOrgName: (): string => {
        return getStorage().getItem(`${storagePrefix}orgName`) as string;
    },
    setLoginRedirectData: (loginRedirect: LoginRedirect, coeusOrgName?: string) => {
        getStorage().setItem(`${storagePrefix}login_redirect`, JSON.stringify(loginRedirect));
        const queryParams = new URLSearchParams(loginRedirect.auth_uri);
        const orgName = queryParams.get('org') ?? coeusOrgName;
        orgName && getStorage().setItem(`${storagePrefix}orgName`, orgName);
    },
    getLoginRedirectData: (): LoginRedirect => {
        return JSON.parse(getStorage().getItem(`${storagePrefix}login_redirect`) as string);
    },
    clearLoginRedirectData: () => {
        getStorage().removeItem(`${storagePrefix}login_redirect`);
        getStorage().removeItem(`${storagePrefix}orgName`);
    },
    getDecodedToken: (): DecodedAccessToken | null => {
        return storage.getToken() ? jwt_decode(storage.getToken()) : null;
    },
    getSelectedOrgId: (): string | null => {
        return getStorage().getItem(`${storagePrefix}selected_org_id`) as string;
    },
    setSelectedOrgId: (orgId: number) => {
        getStorage().setItem(`${storagePrefix}selected_org_id`, `${orgId}`);
    },
    removeSelectedOrgId: () => {
        getStorage().removeItem(`${storagePrefix}selected_org_id`);
    },
    getAppData: (): VBCAppStorageData => {
        return JSON.parse(getStorage().getItem(`${storagePrefix}app`) as string);
    },
    setAppData: (vbcAppData: VBCAppStorageData) => {
        getStorage().setItem(`${storagePrefix}app`, JSON.stringify(vbcAppData));
    },
    clearAppData: () => {
        getStorage().removeItem(`${storagePrefix}app`);
    },
    clearLocalStorage: () => {
        return getStorage().clear();
    },
    getDocumentReviewParams: (): DocumentReviewParams => {
        return (
            JSON.parse(getStorage().getItem(`${storagePrefix}document_review_params`) as string) ||
            {}
        );
    },
    setDocumentReviewParams: (documentReviewParams: DocumentReviewParams) => {
        getStorage().setItem(
            `${storagePrefix}document_review_params`,
            JSON.stringify(documentReviewParams)
        );
    },
    clearDocumentReviewParams: () => {
        getStorage().removeItem(`${storagePrefix}document_review_params`);
    },
    getWarningMessageDismissed: (): string => {
        return getStorage().getItem(`${storagePrefix}warning-dismissed`) as string;
    },
    setWarningMessageDismissed: (dismissed: boolean) => {
        getStorage().setItem(`${storagePrefix}warning-dismissed`, `${dismissed}`);
    },
    setPostLoginRedirectURL: (route: string) => {
        getStorage().setItem(`${storagePrefix}post-login-redirect`, `${route}`);
    },
    getPostLoginRedirectURL: (): string => {
        return getStorage().getItem(`${storagePrefix}post-login-redirect`) as string;
    },
    clearPostLoginRedirectURL: () => {
        getStorage().removeItem(`${storagePrefix}post-login-redirect`);
    },
    setLogoutHint: (logoutHint: string) => {
        getStorage().setItem(`${storagePrefix}logout-hint`, logoutHint);
    },
    getLogoutHint: () => {
        return getStorage().getItem(`${storagePrefix}logout-hint`) as string;
    },
};

export default storage;
