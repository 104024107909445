import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppConstant } from 'common';

interface FilterState {
    [tableName: string]: {
        searchTerm?: string;
        resourceType?: string;
        status?: string;
        searchAcrossAllFiles?: boolean;
        entityType?: string;
    };
}

const initialState: FilterState = {
    [AppConstant.TABLE_NAMES.CUSTOMERS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.USERS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.ORG_GROUPS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.INVITED_USERS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.PARTICIPANTS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.HEALTH_PLANS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.ORGANIZATIONS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.PARTICIPANT_POLICIES_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.BAA_REVIEW_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.ALL_CLAIMS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.CLAIM_REPORTS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.OUTCOMES_REPORTS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.ALL_TPAS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.SYNTHETIC_VBC_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.TPA_CLAIMS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.TPA_PATIENT_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.DRUG_FAILURE_REPORT_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.HEALTH_PLANS_UPLOAD_STATUS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.DRAFT_CLAIMS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.MANAGE_APP_ACCESS]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.VBC_CAMPAIGN_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.VBC_MARKETPLACE_PAYER_OFFERS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.VBC_PATIENTS_SUPPORTING_INFO]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.NOTIFICATIONS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.ALL_CONTRACTS_LIST]: {
        searchTerm: '',
        entityType: '',
    },
    [AppConstant.TABLE_NAMES.ALL_OPERATIONAL_INFO_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.INVOICE_INFORMATION_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.DOCUMENT_LIST]: {
        searchTerm: '',
        resourceType: '',
        status: '',
        searchAcrossAllFiles: false,
    },
    [AppConstant.TABLE_NAMES.DASHBOARD_PAYER_LIST]: {
        searchTerm: '',
    },
};

export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setFilterSearchTerm: (
            state,
            {
                payload,
            }: PayloadAction<{
                tableName: string;
                searchTerm?: string;
                resourceType?: string;
                status?: string;
                searchAcrossAllFiles?: boolean;
                entityType?: string;
            }>
        ) => {
            const {
                tableName,
                searchTerm,
                resourceType,
                status,
                searchAcrossAllFiles,
                entityType,
            } = payload;
            state[tableName] = {
                ...state[tableName],
                ...(searchTerm !== undefined && { searchTerm: searchTerm.toLowerCase() }),
                ...(resourceType !== undefined && { resourceType }),
                ...(status !== undefined && { status }),
                ...(searchAcrossAllFiles !== undefined && { searchAcrossAllFiles }),
                ...(entityType !== undefined && { entityType }),
            };
        },

        resetFilters: (state, { payload }: PayloadAction<{ tableName: string }>) => {
            const { tableName } = payload;
            if (initialState[tableName]) {
                state[tableName] = { ...initialState[tableName] }; // Reset to initial values
            }
        },
    },
});

export const { setFilterSearchTerm, resetFilters } = filterSlice.actions;
export default filterSlice.reducer;
