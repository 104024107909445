export class PermissionConstants {
    /*
        xxxxx denotes unused permission
    */
    public static MY_USER_VIEW = 'MY_USER.VIEW';
    public static MY_USER_EDIT = 'MY_USER.EDIT';

    public static MANUFACTURER_INVITE = 'MANUFACTURER.INVITE';
    public static MANUFACTURER_VIEW = 'MANUFACTURER.VIEW';
    public static MANUFACTURER_MANAGE = 'MANUFACTURER.MANAGE';

    public static VIEW_ALL_ORGANIZATIONS = 'VIEW_ALL_ORGANIZATIONS';
    public static ORGANIZATION_DETAILS_EDIT = 'ORGANIZATION_DETAILS.EDIT';

    public static BAA_VIEW = 'BAA.VIEW';
    public static BAA_UPLOAD = 'BAA.UPLOAD'; // xxxxx
    public static BAA_EDIT = 'BAA.EDIT'; // xxxxx
    public static BAA_RESUBMIT = 'BAA.RESUBMIT'; // xxxxx
    public static BAA_APPROVE_REJECT = 'BAA.APPROVE_REJECT';

    public static MANAGE_USERS_VIEW = 'MANAGE_USERS.VIEW';
    public static MANAGE_USERS_VIEW_ASSIGNED_VBCS = 'MANAGE_USERS.VIEW_ASSIGNED_VBCS';
    public static MANAGE_USERS_INVITE = 'MANAGE_USERS.INVITE';
    public static MANAGE_USERS_INVITE_ASSIGNED_VBCS = 'MANAGE_USERS.INVITE_ASSIGNED_VBCS';
    public static MANAGE_USERS_MANAGE_GROUPS = 'MANAGE_USERS.MANAGE_GROUPS';
    public static MANAGE_USERS_MANAGE_GROUPS_ASSIGNED_VBCS =
        'MANAGE_USERS.MANAGE_GROUPS_ASSIGNED_VBCS';

    public static MANAGE_GROUP_USER_VIEW = 'MANAGE_GROUP_USER.VIEW'; // xxxxx
    public static MANAGE_GROUP_USER_CREATE = 'MANAGE_GROUP_USER.CREATE'; // xxxxx
    public static MANAGE_GROUP_USER_EDIT = 'MANAGE_GROUP_USER.EDIT'; // xxxxx
    public static MANAGE_GROUP_USER_MANAGE_USERS = 'MANAGE_GROUP_USER.MANAGE_USERS'; // xxxxx
    public static MANAGE_GROUP_USER_VIEW_ASSIGNED_VBCS = 'MANAGE_GROUP_USER.VIEW_ASSIGNED_VBCS'; // xxxxx
    public static MANAGE_GROUP_USER_CREATE_ASSIGNED_VBCS = 'MANAGE_GROUP_USER.CREATE_ASSIGNED_VBCS'; // xxxxx
    public static MANAGE_GROUP_USER_EDIT_ASSIGNED_VBCS = 'MANAGE_GROUP_USER.EDIT_ASSIGNED_VBCS'; // xxxxx
    public static MANAGE_GROUP_USER_MANAGE_USERS_ASSIGNED_VBCS =
        'MANAGE_GROUP_USER.MANAGE_USERS_ASSIGNED_VBCS'; // xxxxx

    public static MANAGE_VBC_ACCESS = 'MANAGE_VBC_ACCESS';
    public static MANAGE_VBC_ACCESS_ASSIGNED_VBCS = 'MANAGE_VBC_ACCESS_ASSIGNED_VBCS';

    public static CREATE_SYNTHETIC_APP = 'CREATE_SYNTHETIC_APP';
    public static CONFIGURE_SYNTHETIC_APP = 'CONFIGURE_SYNTHETIC_APP'; // xxxxx
    public static SUBMIT_TPA_CLAIM = 'SUBMIT_TPA_CLAIM';
    public static TPA_REPORTS = 'TPA_REPORTS';
    public static INVITE_TPA = 'INVITE_TPA';
    public static CONFIGURE_TPA = 'CONFIGURE_TPA'; // xxxxx

    public static VBC_CONFIGURATION = 'VBC_CONFIGURATION';

    public static DASHBOARD_ACCESS = 'DASHBOARD.ACCESS';

    public static VBC_LIST_PAYERS = 'VBC.LIST_PAYERS';
    public static VBC_INVITE_PAYER = 'VBC.INVITE_PAYER';

    public static VBC_MANAGE_CLAIM_FILE_VERSION = 'VBC.MANAGE.CLAIM_FILE_VERSION';
    public static VBC_MANAGE_CLAIM_WEB_FORM = 'VBC.MANAGE.CLAIM_WEB_FORM';
    public static VBC_MANAGE_MONITORING_RULES = 'VBC.MANAGE.MONITORING_RULES';

    public static PRIMARY_POLICIES_ADD_PRIMARY_POLICY = 'PRIMARY_POLICIES.ADD_PRIMARY_POLICY';
    public static PRIMARY_POLICIES_VIEW_PRIMARY_POLICY = 'PRIMARY_POLICIES.VIEW_PRIMARY_POLICY';
    public static PRIMARY_POLICIES_EDIT_PRIMARY_POLICY = 'PRIMARY_POLICIES.EDIT_PRIMARY_POLICY';
    public static PRIMARY_POLICIES_REVIEW_PRIMARY_POLICY = 'PRIMARY_POLICIES.REVIEW_PRIMARY_POLICY';
    public static PRIMARY_POLICIES_MANUFACTURER_REVIEW_PRIMARY_POLICY =
        'PRIMARY_POLICIES.MANUFACTURER_REVIEW_PRIMARY_POLICY';

    public static HEALTH_PLAN_POLICIES_VIEW = 'HEALTH_PLAN_POLICIES.VIEW';
    public static HEALTH_PLAN_POLICIES_EDIT = 'HEALTH_PLAN_POLICIES.EDIT';
    public static HEALTH_PLAN_POLICIES_REVIEW = 'HEALTH_PLAN_POLICIES.REVIEW';
    public static HEALTH_PLAN_POLICIES_MANUFACTURER_REVIEW =
        'HEALTH_PLAN_POLICIES.MANUFACTURER_REVIEW';

    public static HEALTH_PLAN_ADD = 'HEALTH_PLAN.ADD';
    public static HEALTH_PLAN_IMPORT = 'HEALTH_PLAN.IMPORT';
    public static HEALTH_PLAN_MULTIPLE_PLAN_UPLOAD = 'HEALTH_PLAN.MULTIPLE_PLAN_UPLOAD';
    public static HEALTH_PLAN_VIEW = 'HEALTH_PLAN.VIEW';
    public static HEALTH_PLAN_EDIT = 'HEALTH_PLAN.EDIT';
    public static HEALTH_PLAN_MOVE = 'HEALTH_PLAN.MOVE';
    public static HEALTH_PLAN_REVIEW = 'HEALTH_PLAN.REVIEW';
    public static HEALTH_PLAN_MANUFACTURER_REVIEW = 'HEALTH_PLAN.MANUFACTURER_REVIEW';

    public static CLAIM_UPLOAD_CLAIM_FILE = 'CLAIM.UPLOAD_CLAIM_FILE';
    public static CLAIM_ADD_CLAIM_WEB_FORM = 'CLAIM.ADD_CLAIM_WEB_FORM';
    public static CLAIM_VIEW_CLAIM_DETAILS = 'CLAIM.VIEW_CLAIM_DETAILS';
    public static CLAIM_DRAFT_CLAIMS_ACCESS = 'CLAIM.DRAFT_CLAIMS.ACCESS';
    public static CLAIM_DRAFT_CLAIMS_EDIT = 'CLAIM.DRAFT_CLAIMS.EDIT';

    public static CLAIM_STATUS_ACCESS = 'CLAIM_STATUS.ACCESS';
    public static CLAIM_STATUS_VIEW_ERROR_MESSAGE = 'CLAIM_STATUS.VIEW_ERROR_MESSAGE';

    public static DATA_INTAKE_ACCESS = 'DATA_INTAKE.ACCESS';
    public static DATA_INTAKE_ADD_RECORD = 'DATA_INTAKE.ADD_RECORD';
    public static DATA_INTAKE_VIEW_RECORD = 'DATA_INTAKE.VIEW_RECORD';
    public static DATA_INTAKE_EDIT_RECORD = 'DATA_INTAKE.EDIT_RECORD';

    public static OUTCOMES_REPORTS_IDENTIFY_CONTRACT_NON_COMPLIANT_CLAIM =
        'OUTCOMES_REPORTS.IDENTIFY_CONTRACT_NON_COMPLIANT_CLAIM';
    public static OUTCOMES_REPORTS_VIEW = 'OUTCOMES_REPORTS.VIEW';
    public static OUTCOMES_REPORTS_REVIEW = 'OUTCOMES_REPORTS.REVIEW';
    public static OUTCOMES_REPORTS_MANUFACTURER_REVIEW = 'OUTCOMES_REPORTS.MANUFACTURER_REVIEW';

    public static STATUS_REPORTS_ACCESS = 'STATUS_REPORTS.ACCESS';
    public static MONITORING_REPORT_GENERATE_MONITORING_REPORT =
        'MONITORING_REPORT.GENERATE_MONITORING_REPORT';
    public static CUSTOM_REPORTS = 'CUSTOM_REPORTS';

    public static MARKETPLACE_CAMPAIGN_VIEW = 'MARKETPLACE_CAMPAIGN.VIEW';
    public static MARKETPLACE_CAMPAIGN_CREATE = 'MARKETPLACE_CAMPAIGN.CREATE';
    public static MARKETPLACE_CAMPAIGN_EDIT = 'MARKETPLACE_CAMPAIGN.EDIT';
    public static MARKETPLACE_CAMPAIGN_OFFERS_VIEW = 'MARKETPLACE_CAMPAIGN.OFFERS_VIEW'; // xxxxx
    public static MARKETPLACE_CAMPAIGN_OFFERS_EDIT = 'MARKETPLACE_CAMPAIGN.OFFERS_EDIT'; // xxxxx
    public static MARKETPLACE_CAMPAIGN_TARGET_VIEW = 'MARKETPLACE_CAMPAIGN.TARGET_VIEW'; // xxxxx
    public static MARKETPLACE_CAMPAIGN_TARGET_CREATE = 'MARKETPLACE_CAMPAIGN.TARGET_CREATE'; // xxxxx
    public static MARKETPLACE_CAMPAIGN_TARGET_EDIT = 'MARKETPLACE_CAMPAIGN.TARGET_EDIT'; // xxxxx

    public static INFORMATIONAL_NOTIFICATION_CREATE = 'INFORMATIONAL_NOTIFICATION.CREATE';
    public static INFORMATIONAL_NOTIFICATION_EDIT = 'INFORMATIONAL_NOTIFICATION.EDIT';

    public static ADMIN_PORTAL_MANAGE_USER = 'MANAGE_USERS.DISABLE';
    public static ADMIN_PORTAL_VIEW_USERS = 'VIEW_ALL_USERS';

    public static REBATE_CONTRACT_MANAGEMENT_ADD = 'REBATE_CONTRACT_MANAGEMENT.ADD';
    public static REBATE_CONTRACT_MANAGEMENT_EDIT = 'REBATE_CONTRACT_MANAGEMENT.EDIT';
    public static REBATE_CONTRACT_MANAGEMENT_VIEW = 'REBATE_CONTRACT_MANAGEMENT.VIEW';

    public static REBATE_OPERATIONAL_INFORMATION_ADD = 'REBATE_OPERATIONAL_INFORMATION.ADD';
    public static REBATE_OPERATIONAL_INFORMATION_EDIT = 'REBATE_OPERATIONAL_INFORMATION.EDIT';
    public static REBATE_OPERATIONAL_INFORMATION_VIEW = 'REBATE_OPERATIONAL_INFORMATION.VIEW';

    public static INTELLIGENT_WORKSPACE_DOCUMENT_MANAGEMENT_ADD =
        'INTELLIGENT_WORKSPACE_DOCUMENT_MANAGEMENT.ADD';
    public static INTELLIGENT_WORKSPACE_DOCUMENT_MANAGEMENT_EDIT =
        'INTELLIGENT_WORKSPACE_DOCUMENT_MANAGEMENT.EDIT';
    public static INTELLIGENT_WORKSPACE_DOCUMENT_MANAGEMENT_VIEW =
        'INTELLIGENT_WORKSPACE_DOCUMENT_MANAGEMENT.VIEW';

    public static REBATE_INVOICE_INFORMATION_ADD = 'REBATE_INVOICE_INFORMATION.ADD';
    public static REBATE_INVOICE_INFORMATION_EDIT = 'REBATE_INVOICE_INFORMATION.EDIT';
    public static REBATE_INVOICE_INFORMATION_VIEW = 'REBATE_INVOICE_INFORMATION.VIEW';

    /*
        Old Permissions
        xxxxx denotes that the permission was not used in the frontend code
        ***** denotes the respective new permission
        TODO: Remove this once the New Roles and Permission story and related issues are done.

        CUSTOMERS_APP_ACCESS = 'platform.tenant.app-access'; xxxxx
        CUSTOMERS_APP_FEATURE_ACCESS = 'platform.tenant.app-feature-access'; xxxxx
        CUSTOMERS_CREATE = 'platform.tenant.create'; ***** MANUFACTURER_INVITE
        CUSTOMERS_UPDATE = 'platform.tenant.update'; ***** MANUFACTURER_MANAGE
        CUSTOMERS_VIEW = 'platform.tenant.view'; ***** MANUFACTURER_VIEW

        USER_PROFILE_CHANGE_EMAIL = 'platform.user-profile.change-email'; xxxxx
        USER_PROFILE_PASSWORD_RESET = 'platform.user-profile.password-reset'; xxxxx
        USER_PROFILE_UPDATE = 'platform.user-profile.update'; ***** MY_USER_EDIT
        USER_PROFILE_VIEW = 'platform.user-profile.view'; ***** MY_USER_VIEW

        ORGANIZATION_APP_ACCESS_GROUP = 'platform.organization.app-access-group'; xxxxx
        ORGANIZATION_CREATE = 'platform.organization.create'; xxxxx
        ORGANIZATION_INVITE_USER = 'platform.organization.invite-user'; ***** MANAGE_USERS_INVITE
        ORGANIZATION_MANAGE_GROUP = 'platform.organization.manage-group'; ***** MANAGE_USERS_MANAGE_GROUPS
        ORGANIZATION_MANAGE_USER = 'platform.organization.manage-user'; ***** MANAGE_USERS_INVITE
        ORGANIZATION_UPDATE = 'platform.organization.update'; ***** ORGANIZATION_DETAILS_EDIT
        ORGANIZATION_VIEW = 'platform.organization.view'; ***** No New Permission for this. Using MY_USER_VIEW
        ORGANIZATION_BAA_CREATE = 'platform.organization.baa.create'; xxxxx
        ORGANIZATION_BAA_VERIFY = 'platform.organization.baa.verify'; ***** BAA_APPROVE_REJECT
        ORGANIZATION_BAA_VIEW = 'platform.organization.baa.view'; ***** BAA_VIEW

        USER_CREATE = 'platform.user.create'; xxxxx
        USER_UPDATE = 'platform.user.update'; xxxxx

        VBC_CLAIM_CONTRACTING_ENTITY_CREATE = 'vbc.claim.contracting-entity.create'; xxxxx
        VBC_CLAIM_CONTRACTING_ENTITY_UPDATE = 'vbc.claim.contracting-entity.update'; xxxxx
        VBC_CLAIM_CONTRACTING_ENTITY_VIEW = 'vbc.claim.contracting-entity.view'; xxxxx
        VBC_CLAIM_PLAN_CREATE = 'vbc.claim.plan.create'; ***** CLAIM_UPLOAD_CLAIM_FILE
        VBC_CLAIM_PLAN_UPDATE = 'vbc.claim.plan.update'; xxxxx
        VBC_CLAIM_PLAN_VIEW = 'vbc.claim.plan.view'; xxxxx
        VBC_CLAIM_TENANT_CREATE = 'vbc.claim.tenant.create'; xxxxx
        VBC_CLAIM_TENANT_UPDATE = 'vbc.claim.tenant.update'; xxxxx
        VBC_CLAIM_TENANT_VIEW = 'vbc.claim.tenant.view'; xxxxx
        VBC_CLAIM_VERIFY = 'vbc.claim.verify'; xxxxx
        VBC_CONFIGURE = 'vbc.configure'; ***** VBC_CONFIGURATION
        VBC_CONTRACTING_ENTITY_INVITE = 'vbc.contracting-entity.invite'; ***** VBC_INVITE_PAYER

        VBC_COVERAGE_VERIFICATION_CONTRACTING_ENTITY_VIEW = 'vbc.coverage-verification.contracting-entity.view'; xxxxx
        VBC_COVERAGE_VERIFICATION_CREATE = 'vbc.coverage-verification.create'; ***** PRIMARY_POLICIES_ADD_PRIMARY_POLICY
        VBC_COVERAGE_VERIFICATION_PLAN_VIEW = 'vbc.coverage-verification.plan.view'; xxxxx
        VBC_COVERAGE_VERIFICATION_TENANT_VIEW = 'vbc.coverage-verification.tenant.view'; ***** PRIMARY_POLICIES_VIEW_PRIMARY_POLICY
        VBC_COVERAGE_VERIFICATION_UPDATE = 'vbc.coverage-verification.update'; ***** PRIMARY_POLICIES_EDIT_PRIMARY_POLICY
        VBC_COVERAGE_VERIFICATION_VERIFY = 'vbc.coverage-verification.verify'; ***** PRIMARY_POLICIES_REVIEW_PRIMARY_POLICY
        VBC_COVERAGE_VERIFICATION_MED_AFFAIRS_VERIFY = 'vbc.coverage-verification.medical-affair.verify'; ***** PRIMARY_POLICIES_REVIEW_PRIMARY_POLICY
        VBC_COVERAGE_VERIFICATION_CUSTOMER_VERIFY = 'vbc.coverage-verification.customer.verify'; ***** PRIMARY_POLICIES_MANUFACTURER_REVIEW_PRIMARY_POLICY
        VBC_DELEGATE_INVITE = 'vbc.delegate.invite'; xxxxx

        VBC_HEALTH_PLAN_TENANT_VIEW = 'vbc.health-plan.tenant.view'; xxxxx
        VBC_HEALTH_PLAN_CE_VIEW = 'vbc.health-plan.contracting-entity.view'; xxxxx
        VBC_HEALTH_PLAN_PLAN_VIEW = 'vbc.health-plan.plan.view'; xxxxx
        VBC_HEALTH_PLAN_CREATE = 'vbc.health-plan.create'; ***** HEALTH_PLAN_ADD
        VBC_HEALTH_PLAN_UPDATE = 'vbc.health-plan.update'; ***** HEALTH_PLAN_EDIT
        VBC_HEALTH_PLAN_VERIFY = 'vbc.health-plan.verify'; ***** HEALTH_PLAN_REVIEW
        VBC_HEALTH_PLAN_IMPORT = 'vbc.health-plan.import'; ***** HEALTH_PLAN_IMPORT
        VBC_HEALTH_PLAN_CUSTOMER_REVIEW = 'vbc.health-plan.customer-review'; ***** HEALTH_PLAN_MANUFACTURER_REVIEW
        VBC_HEALTH_PLAN_POLICY_MED_AFFAIRS_VERIFY = 'vbc.health-plan-policy.medical-affair.verify'; ***** HEALTH_PLAN_POLICIES_REVIEW
        VBC_HEALTH_PLAN_POLICY_CUSTOMER_VERIFY = 'vbc.health-plan-policy.customer.verify'; ***** HEALTH_PLAN_POLICIES_MANUFACTURER_REVIEW

        VBC_GENERIC_INTAKE_TENANT_VIEW = 'vbc.generic-intake.tenant.view'; xxxxx
        VBC_GENERIC_INTAKE_CE_VIEW = 'vbc.generic-intake.contracting-entity.view'; xxxxx
        VBC_GENERIC_INTAKE_PLAN_VIEW = 'vbc.generic-intake.plan.view'; xxxxx
        VBC_GENERIC_INTAKE_CREATE = 'vbc.generic-intake.create'; ***** DATA_INTAKE_ADD_RECORD
        VBC_GENERIC_INTAKE_UPDATE = 'vbc.generic-intake.update'; ***** DATA_INTAKE_EDIT_RECORD
    */
}
