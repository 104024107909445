import { configureStore } from '@reduxjs/toolkit';

import { getRuntimeEnv } from 'utils/environment';

import appSettingsSlice from './appSettingsSlice';
import authSlice from './authSlice';
import filterSlice from './filterSlice';
import intelligentWorkspaceSlice from './intelligentWorkspaceSlice';
import loaderSlice from './loaderSlice';
import organizationDetailSlice from './organizationDetailSlice';
import platformAppsSlice from './platformAppsSlice';
import reloadSlice from './reloadSlice';
import rolesSlice from './rolesSlice';
import toastSlice from './toastSlice';
import tpaSlice from './tpaSlice';
import vbcSlice from './vbcSlice';

const store = configureStore({
    reducer: {
        auth: authSlice,
        toast: toastSlice,
        loader: loaderSlice,
        platformApps: platformAppsSlice,
        reload: reloadSlice,
        filter: filterSlice,
        organizationDetail: organizationDetailSlice,
        vbc: vbcSlice,
        roles: rolesSlice,
        appSettings: appSettingsSlice,
        tpa: tpaSlice,
        intelligentWorkspace: intelligentWorkspaceSlice,
    },
    devTools: getRuntimeEnv() !== 'prod',
});

export const dispatch = store.dispatch;

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
