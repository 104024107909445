import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Breadcrumb {
    label: string;
    path: string;
    folderId?: string; // Store folder ID for dynamic lookups
}

interface WorkspaceState {
    breadcrumbs: Breadcrumb[];
    currentFolder?: string;
}

const initialState: WorkspaceState = {
    breadcrumbs: [{ label: 'Document Management', path: '/' }],
    currentFolder: '',
};

export const intelligentWorkspaceSlice = createSlice({
    name: 'intelligentWorkspace',
    initialState,
    reducers: {
        setBreadcrumbs: (state, action: PayloadAction<Breadcrumb[]>) => {
            state.breadcrumbs = action.payload;
        },
        addBreadcrumb: (state, action: PayloadAction<Breadcrumb>) => {
            // Avoid duplicate entries
            if (!state.breadcrumbs.some((b) => b.path === action.payload.path)) {
                state.breadcrumbs.push(action.payload);
            }
            state.currentFolder = action.payload.folderId;
        },
        removeBreadcrumb: (state, action: PayloadAction<string>) => {
            // Remove breadcrumbs beyond the selected one
            const index = state.breadcrumbs.findIndex((b) => b.path === action.payload);
            if (index !== -1) {
                state.breadcrumbs = state.breadcrumbs.slice(0, index + 1);
            }
            state.currentFolder = state.breadcrumbs[state.breadcrumbs.length - 1]?.folderId || '';
        },
        resetBreadcrumbs: (state) => {
            state.breadcrumbs = [{ label: 'Document Management', path: '/' }];
            state.currentFolder = '';
        },
    },
});

export const { setBreadcrumbs, addBreadcrumb, removeBreadcrumb, resetBreadcrumbs } =
    intelligentWorkspaceSlice.actions;
export default intelligentWorkspaceSlice.reducer;
