const getDateWithOffset = (date: string) => {
    const dateObj = new Date(date);
    const timezoneOffset = dateObj.getTimezoneOffset();
    if (timezoneOffset < 0) {
        return new Date(dateObj.getTime() + Math.abs(timezoneOffset * 60000));
    }
    return new Date(dateObj.getTime() - Math.abs(timezoneOffset * 60000));
};

export const getFormattedDate = (date: string): string => {
    if (date) {
        return getDateWithOffset(date).toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
        });
    }
    return '';
};

export const is72HoursPassed = (date: string): boolean => {
    const checkDate = new Date(date).getTime();
    const hour72 = 1000 * 60 * 60 * 72;
    const oneDayPassed = Date.now() - hour72;
    return checkDate < oneDayPassed;
};

export const isDatePassed = (date: string): boolean => {
    const checkDate = new Date(date);
    const currentDate = new Date();
    return checkDate < currentDate;
};

export const getMonthAndYearFromDate = (date: string): string => {
    return getDateWithOffset(date).toLocaleDateString('en-US', {
        month: 'long',
        year: 'numeric',
    });
};

export const getFormattedDateWithTime = (date: string): string => {
    if (date) {
        return getDateWithOffset(date)
            .toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
            })
            .replace(',', '');
    }
    return '';
};

export const getFormattedDateWithTimeWithoutOffset = (date: string): string => {
    if (date) {
        return new Date(date)
            .toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
            })
            .replace(',', '');
    }
    return '';
};

export const getFormattedDateLong = (date: string): string => {
    // example: August 07, 2023 at 05:09 PM
    if (date) {
        return getDateWithOffset(date).toLocaleString('en-US', {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        });
    }
    return '';
};

export const convertDateFormatHyphen = (dateStr?: string) => {
    //Example : returns yyyy-mm-dd format
    const date = (dateStr ? getDateWithOffset(dateStr) : new Date()).toISOString();
    const formatted = date.split('T')[0];
    return formatted;
};

export const getISODateWithoutTimezone = (reviewedDate: string) => {
    return new Date(reviewedDate).toISOString().split('.')[0];
};

export const getFormattedDateTimeForDateInput = (dateStr?: string) => {
    const date = dateStr ? new Date(getDateWithOffset(dateStr)) : new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

export const getDateTimeAfterThirtyMinutes = (date?: string) => {
    const currentDate = date ? new Date(getDateWithOffset(date)) : new Date();
    const newDate = new Date(currentDate.getTime() + 30 * 60 * 1000); // Adding 30 mins
    return newDate;
};

// output format: mm/dd/yyyy
export const formatSimpleDateString = (date?: string) => {
    if (date) {
        return new Date(date).toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
        });
    }
    return '';
};

export const getCopyrightYear = () => new Date().getFullYear();
