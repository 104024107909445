import { useAppSelector } from 'hooks';
import storage from 'utils/storage';

const useIsCustomerUser = (): boolean => {
    const vbcData = storage.getAppData();
    const { userOrg } = useAppSelector((state) => state.auth);
    return vbcData?.appOrgId === userOrg?.id;
};

export default useIsCustomerUser;
