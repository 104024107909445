import { FunctionComponent } from 'react';

import { ReactComponent as KeyIcon } from 'Assets/svg-icons/access.svg';
import { ReactComponent as ApproveIcon } from 'Assets/svg-icons/approve.svg';
import { ReactComponent as ApprovedIcon } from 'Assets/svg-icons/approved.svg';
import { ReactComponent as ArrowLeftIcon } from 'Assets/svg-icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from 'Assets/svg-icons/arrow-right.svg';
import { ReactComponent as BackIcon } from 'Assets/svg-icons/back.svg';
import { ReactComponent as BarChartIcon } from 'Assets/svg-icons/bar-chart.svg';
import { ReactComponent as ExpandIcon } from 'Assets/svg-icons/chevron-right.svg';
import { ReactComponent as CircleWithTickIcon } from 'Assets/svg-icons/CircleWithTickIcon.svg';
import { ReactComponent as ClipboardHeartFillIcon } from 'Assets/svg-icons/clipboard-heart-fill.svg';
import { ReactComponent as CloseIcon } from 'Assets/svg-icons/close.svg';
import { ReactComponent as CoeusIcon } from 'Assets/svg-icons/coeus.svg';
import { ReactComponent as CopyIcon } from 'Assets/svg-icons/copy.svg';
import { ReactComponent as DashboardIcon } from 'Assets/svg-icons/dashboard.svg';
import { ReactComponent as DisableIcon } from 'Assets/svg-icons/disable.svg';
import { ReactComponent as DownloadIcon } from 'Assets/svg-icons/download.svg';
import { ReactComponent as DragDropIcon } from 'Assets/svg-icons/drag-drop.svg';
import { ReactComponent as EditIcon } from 'Assets/svg-icons/edit.svg';
import { ReactComponent as EnableIcon } from 'Assets/svg-icons/enable.svg';
import { ReactComponent as EnvolopeIcon } from 'Assets/svg-icons/envelope-check.svg';
import { ReactComponent as ErrorIcon } from 'Assets/svg-icons/error.svg';
import { ReactComponent as FileRuledIcon } from 'Assets/svg-icons/file-ruled.svg';
import { ReactComponent as FileIcon } from 'Assets/svg-icons/file.svg';
import { ReactComponent as FileTypeDocx } from 'Assets/svg-icons/filetype-docx.svg';
import { ReactComponent as FileTypePDF } from 'Assets/svg-icons/filetype-pdf.svg';
import { ReactComponent as FileTypePPTX } from 'Assets/svg-icons/filetype-pptx.svg';
import { ReactComponent as FlagIcon } from 'Assets/svg-icons/flag.svg';
import { ReactComponent as FolderIcon } from 'Assets/svg-icons/folder-icon.svg';
import { ReactComponent as AddFolderIcon } from 'Assets/svg-icons/folder-plus.svg';
import { ReactComponent as PlainFolderIcon } from 'Assets/svg-icons/folder.svg';
import { ReactComponent as GroupIcon } from 'Assets/svg-icons/groups.svg';
import { ReactComponent as HeadsetIcon } from 'Assets/svg-icons/headset.svg';
import { ReactComponent as HealthPlanIcon } from 'Assets/svg-icons/health-plans.svg';
import { ReactComponent as ImportIcon } from 'Assets/svg-icons/import.svg';
import { ReactComponent as InboxIcon } from 'Assets/svg-icons/inbox.svg';
import { ReactComponent as InfoIcon } from 'Assets/svg-icons/info.svg';
import { ReactComponent as LogoutIcon } from 'Assets/svg-icons/logout.svg';
import { ReactComponent as ManageUsersIcon } from 'Assets/svg-icons/manage-users.svg';
import { ReactComponent as ManageIcon } from 'Assets/svg-icons/manage.svg';
import { ReactComponent as MarketplaceIcon } from 'Assets/svg-icons/marketplace.svg';
import { ReactComponent as MenuIcon } from 'Assets/svg-icons/menu.svg';
import { ReactComponent as MoreIcon } from 'Assets/svg-icons/more.svg';
import { ReactComponent as MoveIcon } from 'Assets/svg-icons/move.svg';
import { ReactComponent as NotificationIcon } from 'Assets/svg-icons/notification.svg';
import { ReactComponent as OrganizationIcon } from 'Assets/svg-icons/organization.svg';
import { ReactComponent as ParticipantIcon } from 'Assets/svg-icons/participant.svg';
import { ReactComponent as PendingIcon } from 'Assets/svg-icons/pending.svg';
import { ReactComponent as ProfileFillIcon } from 'Assets/svg-icons/person-fill.svg';
import { ReactComponent as ProfileIcon } from 'Assets/svg-icons/person.svg';
import { ReactComponent as AddIcon } from 'Assets/svg-icons/plus.svg';
import { ReactComponent as PolicyVerificationIcon } from 'Assets/svg-icons/policy-verification.svg';
import { ReactComponent as InvoiceIcon } from 'Assets/svg-icons/receipt-cutoff.svg';
import { ReactComponent as RefreshIcon } from 'Assets/svg-icons/refresh.svg';
import { ReactComponent as RejectIcon } from 'Assets/svg-icons/reject.svg';
import { ReactComponent as ReportSecurityIcon } from 'Assets/svg-icons/report-security.svg';
import { ReactComponent as ReportsIcon } from 'Assets/svg-icons/reports.svg';
import { ReactComponent as ResendIcon } from 'Assets/svg-icons/resend.svg';
import { ReactComponent as SearchIcon } from 'Assets/svg-icons/search.svg';
import { ReactComponent as SortDownArrowIcon } from 'Assets/svg-icons/sort-down-arrow.svg';
import { ReactComponent as SortUpArrowIcon } from 'Assets/svg-icons/sort-up-arrow.svg';
import { ReactComponent as StarFillIcon } from 'Assets/svg-icons/star-fill.svg';
import { ReactComponent as SubmissionsIcon } from 'Assets/svg-icons/submissions.svg';
import { ReactComponent as SuccessIcon } from 'Assets/svg-icons/success.svg';
import { ReactComponent as TagsIcon } from 'Assets/svg-icons/tags.svg';
import { ReactComponent as DeleteIcon } from 'Assets/svg-icons/trash.svg';
import { ReactComponent as UploadIcon } from 'Assets/svg-icons/upload.svg';
import { ReactComponent as ViewIcon } from 'Assets/svg-icons/view.svg';
import { ReactComponent as WarningIcon } from 'Assets/svg-icons/warning.svg';

export const SVGIcons: { [icon: string]: FunctionComponent } = {
    HeadsetIcon: HeadsetIcon,
    MenuIcon: MenuIcon,
    AddIcon: AddIcon,
    RefreshIcon: RefreshIcon,
    SearchIcon: SearchIcon,
    EditIcon: EditIcon,
    ProfileIcon: ProfileIcon,
    InboxIcon: InboxIcon,
    LogoutIcon: LogoutIcon,
    SuccessIcon: SuccessIcon,
    ErrorIcon: ErrorIcon,
    ProfileFillIcon: ProfileFillIcon,
    GroupIcon: GroupIcon,
    OrganizationIcon: OrganizationIcon,
    FileIcon: FileIcon,
    KeyIcon: KeyIcon,
    EnvolopeIcon: EnvolopeIcon,
    UploadIcon: UploadIcon,
    StarFillIcon: StarFillIcon,
    FileRuledIcon: FileRuledIcon,
    BarChartIcon: BarChartIcon,
    DeleteIcon: DeleteIcon,
    CloseIcon: CloseIcon,
    DragDropIcon: DragDropIcon,
    FlagIcon: FlagIcon,
    ImportIcon: ImportIcon,
    DashboardIcon: DashboardIcon,
    ParticipantIcon: ParticipantIcon,
    HealthPlanIcon: HealthPlanIcon,
    SubmissionIcon: SubmissionsIcon,
    ReportIcon: ReportsIcon,
    ReportSecurityIcon: ReportSecurityIcon,
    ExpandIcon: ExpandIcon,
    NotificationIcon: NotificationIcon,
    CoeusIcon: CoeusIcon,
    ManageUsersIcon: ManageUsersIcon,
    PolicyVerificationIcon: PolicyVerificationIcon,
    ApprovedIcon: ApprovedIcon,
    WarningIcon: WarningIcon,
    InfoIcon: InfoIcon,
    PendingIcon: PendingIcon,
    MoreIcon: MoreIcon,
    ViewIcon: ViewIcon,
    EnableIcon: EnableIcon,
    DisableIcon: DisableIcon,
    ApproveIcon: ApproveIcon,
    RejectIcon: RejectIcon,
    ResendIcon: ResendIcon,
    ManageIcon: ManageIcon,
    CircleWithTickIcon: CircleWithTickIcon,
    MarketplaceIcon: MarketplaceIcon,
    CopyIcon: CopyIcon,
    MoveIcon: MoveIcon,
    ArrowLeftIcon: ArrowLeftIcon,
    ArrowRightIcon: ArrowRightIcon,
    BackIcon: BackIcon,
    SortDownArrowIcon: SortDownArrowIcon,
    SortUpArrowIcon: SortUpArrowIcon,
    FolderIcon: FolderIcon,
    ClipboardHeartFillIcon: ClipboardHeartFillIcon,
    FileTypeDocx: FileTypeDocx,
    FileTypePDF: FileTypePDF,
    FileTypePPTX: FileTypePPTX,
    DownloadIcon: DownloadIcon,
    AddFolderIcon: AddFolderIcon,
    PlainFolderIcon: PlainFolderIcon,
    InvoiceIcon: InvoiceIcon,
    TagsIcon: TagsIcon,
};
