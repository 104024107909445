import {
    getCoeusEmailDomain,
    getCoeusOrgName,
    getJiraHelpDeskLink,
    getReportIncidentEmail,
    getTechSupportEmail,
} from 'utils/environment';

export class AppConstant {
    public static ONE_COEUS_EMAIL_DOMAIN = getCoeusEmailDomain();
    public static COEUS_ORG_NAME = getCoeusOrgName();
    public static TECH_SUPPORT_EMAIL = getTechSupportEmail();
    public static REPORT_INCIDENT_EMAIL = getReportIncidentEmail();
    public static JIRA_HELP_DESK_LINK = getJiraHelpDeskLink();

    public static ROUTE_PATHS = {
        MY_PROFILE: '/platform/my-profile',
        CUSTOMERS: '/platform/manufacturers',
        CUSTOMER_APP_CONFIG: '/platform/manufacturers/apps',
        ORGANIZATIONS: '/platform/organizations',
        ORGANIZATIONS_ALL: '/platform/organizations/all',
        USERS: '/platform/users',
        LOGIN: '/auth/login',
        AUTH_RESPONSE: '/auth/auth-response',
        LOGOUT: '/auth/logout',
        PLATFORM_TERMS_OF_SERVICE: '/postregistration/terms-of-service',
        CONFIRM_ORG_DETAILS: '/postregistration/confirm-org-details',
        THANK_YOU: '/postregistration/thank-you',
        PLATFORM: '/platform',
        APPS_VBC: '/platform/apps/vbc',
        UNAUTHORIZED: '/unauthorized',
        VBC_PAYERS: '/platform/apps/vbc/payers',
        VBC_HEALTH_PLANS: '/platform/apps/vbc/health-plans',
        VBC_CLINICAL_POLICY_VERIFICATION: '/platform/apps/vbc/clinical-policy-verification',
        VBC_AGREEMENT: '/platform/apps/vbc/agreement',
        VBC_DASHBOARD: '/platform/apps/vbc/dashboard',
        VBC_REPORTS: '/platform/apps/vbc/reports',
        VBC_SUBMISSIONS: '/platform/apps/vbc/submissions',
        VBC_OUTCOMES_REPORT_VIEW: '/platform/apps/vbc/reports/outcomes-reports/view',
        DOCUMENT_REVIEW_LANDING: '/platform/document-review',
        VBC_MARKETPLACE: '/platform/apps/vbc/vbc-marketplace',
        VBC_MARKETPLACE_ALL_PAYER_OFFERS: '/platform/vbc-marketplace/payer-offers',
        VBC_MARKETPLACE_VIEW_PAYER_OFFER: '/platform/vbc-marketplace/payer-offers/view',
        TPA_PORTAL: '/platform/tpa-portal',
        ADMIN_PORTAL: '/platform/admin-portal',
        NOTIFICATIONS: '/platform/notifications',
        SHAREPOINT_FILES: '/platform/sharepoint-files',
        APPS_REBATE: '/platform/apps/rebate',
        REBATE_CONTRACT_MANAGEMENT: '/platform/apps/rebate/contract-management',
        REBATE_OPERATIONAL_INFORMATION: '/platform/apps/rebate/operational-information',
        APPS_INTELLIGENT_WORKSPACE: '/platform/apps/intelligent-workspace',
        INTELLIGENT_WORKSPACE_DOCUMENT_MANAGEMENT:
            '/platform/apps/intelligent-workspace/document-management',
        REBATE_INVOICE_INFORMATION: '/platform/apps/rebate/invoice-information',
    };

    public static API_ROUTES = {
        LOGIN_REDIRECT: '/v1/auth/login/redirect',
        ACCESS_TOKEN: '/v1/auth/login/access-token',
        LOGOUT: '/v1/auth/account/logout',
        USER_ACCOUNT_DETAILS: '/v1/auth/account',
        UPDATE_USER_ACCOUNT: '/v1/users',
        GROUPS_LIST: '/v1/groups',
        CUSTOMERS_LIST: '/v1/tenants',
        CUSTOMER_INVITE: '/v1/tenants',
        CUSTOMER_APPS: '/v1/tenants/{tenant_id}/apps',
        CUSTOMER_INVITE_RESEND: '/v1/tenants/{tenant_id}/resend-invite',
        INVITE_REGISTER_REDIRECT: '/v1/auth/account/invite/register',
        PLATFORM_APPS: '/v1/tenants/platform-apps',
        ORGANIZATION_LIST: '/v1/organizations',
        UPDATE_ORGANIZATION: '/v1/organizations',
        USERS_LIST: '/v1/users',
        ORGANIZATION_USER_INVITE: '/v1/users/invite',
        UPDATE_USER_GROUPS: '/v1/groups/user',
        ORGANIZATION_APP_ACCESS: '/v1/organizations/app-access',
        GET_MY_APPS: '/v1/tenants/apps',
        INVITED_USERS_LIST: '/v1/organizations/user-invites',
        RESEND_USER_INVITE: '/v1/users/resend-invite',
        MANAGE_GROUP: '/v1/groups',
        GET_ROLES: '/v1/roles',
        TENANT_APP_CONFIG: '/v1/tenants/apps',
        VBC_CONTRACTING_ENTITY_INVITE: '/v1/vbc/invite/contracting-entity',
        VBC_PARTICIPANTS: '/v1/vbc/participants',
        VBC_DELEGATE_PARTICIPANTS: '/v1/vbc/participants/delegate',
        PARTICIPANT_RESEND_INVITE: '/v1/vbc/{participant_id}/resend-invite',
        VBC_ACCEPT_TERMS: '/v1/vbc/{organization_id}/accept-terms',
        HEALTH_PLANS: '/v1/vbc/health-plans',
        HEALTH_PLAN_REVIEW: '/v1/vbc/health-plans/review',
        APPROVE_REJECT_HEALTH_PLAN: '/v1/vbc/health-plans/customer-review',
        HEALTH_PLAN_MOVE: '/v1/vbc/health-plans/migrate',
        HEALTH_PLAN_CLINICAL_POLICY: '/v1/vbc/health-plans/clinical-policy',
        HEALTH_PLAN_RESENT_INVITE: '/v1/vbc/health-plans/{health_plan_id}/resend-invite',
        ALL_ROLES: '/v1/roles/all',
        VBC_CLINICAL_POLICY: '/v1/vbc/clinical-policy',
        VBC_CLINICAL_POLICY_REVIEW: '/v1/vbc/clinical-policy/review',
        VBC_CLINICAL_POLICY_MED_AFFAIRS_REVIEW: '/v1/vbc/clinical-policy/medical-affair/review',
        VBC_CLINICAL_POLICY_CUSTOMER_REVIEW: '/v1/vbc/clinical-policy/customer/review',
        UPLOAD_BAA_DOCUMENT: '/v1/organizations/baa',
        BAA_REVIEW: '/v1/organizations/baa/review',
        VBC_CLINICAL_POLICY_PENDING_COUNT: '/v1/vbc/clinical-policy/pending-review/count',
        GET_BAA_PENDING_REVIEW_COUNT: '/v1/organizations/baa/pending-review/count',
        VBC_CLAIM_REPORTS: '/v1/vbc/claims/upload-statuses',
        VBC_CLAIMS: '/v1/vbc/claims',
        VBC_DASHBOARD_ANALYTICS: '/v1/vbc/base-analytics',
        VBC_DETECT_WARRANTY_BREACH: '/v1/vbc/outcomes-reports/detect',
        VBC_OUTCOMES_REPORTS_LIST: '/v1/vbc/outcomes-reports',
        VBC_DATA_INTAKE: '/v1/vbc/generic-intake/files-and-data',
        VBC_CONFIG_DATA_FIELD_DELETE_CONFIRM: '/v1/vbc/generic-intake/files-and-data/data-exists',
        VBC_HEALTH_PLANS_IMPORT: '/v1/vbc/health-plans/import',
        POWERBI_REPORT: '/v1/powerbi/reports',
        VBC_MONITORING_REPORTS: '/v1/vbc/monitoring-reports',
        VBC_GENERATE_MONITORING_REPORT: '/v1/vbc/monitoring-reports/detect',
        VBC_PLATFORM_PATIENT_ID_LIST: '/v1/vbc/platform-patient-ids',
        VBC_CLAIM_FORM: '/v1/vbc/claims/form',
        VBC_CLAIM_FORM_SUBMIT: '/v1/vbc/claims/form/submit',
        VBC_STATUS_REPORT_PATIENT_STATUS: '/v1/vbc/outcomes-reports/status-report',
        VBC_V2_CLAIM_FORM_SUBMIT: '/v1/vbc/claims/v2/form/submit',
        VBC_V2_CLAIM_FORM_SAVE: '/v1/vbc/claims/v2/form',
        VBC_DOWNLOAD_COMBINED_REPORTS: '/v1/vbc/custom-reports/vertex/combined-report',
        VBC_OUTCOMES_REPORT_READY_FOR_PAYER: '/v1/vbc/outcomes-reports/show-to-payer',
        VBC_HEALTH_PLAN_UPLOAD: '/v1/vbc/health-plans/bulk-upload',
        VBC_HEALTH_PLANS_UPLOAD_STATUS: '/v1/vbc/health-plans/bulk-upload/status-report',
        VBC_OUTCOMES_REPORT_MEDICAL_REVIEWER_REVIEW:
            '/v1/vbc/outcomes-reports/medical-affair-review',
        VBC_OUTCOMES_REPORT_MANUFACTURER_REVIEW: '/v1/vbc/outcomes-reports/manufacturer-review',
        PAYER_OFFER_DETAILS: '/v1/vbc-marketplace/campaign/offer',
        VBC_MARKETPLACE_CAMPAIGNS: '/v1/vbc-marketplace/campaign',
        VBC_MARKETPLACE_PAYER_ACTION_DETAILS: '/v1/vbc-marketplace/campaign/action-details',
        VBC_ALL_PAYERS_LIST: '/v1/organizations/all-payers',
        VBC_MARKETPLACE_TARGET_LIST: '/v1/vbc-marketplace/campaign/targets',
        VBC_MARKETPLACE_ALL_PAYER_OFFERS: '/v1/vbc-marketplace/campaign/offers',
        VBC_MARKETPLACE_CAMPAIGN_CANCEL: '/v1/vbc-marketplace/campaign/cancel',
        VBC_MARKETPLACE_CAMPAIGN_STATS: '/v1/vbc-marketplace/campaign/stats',
        VBC_PLATFORM_PATIENT_ID: '/v1/vbc/platform-patient-id',
        VBC_PAYER_PATIENT_IDS_LIST: '/v1/vbc/payer-patient-ids',
        VBC_PATIENT_SUPPORTING_INFO: '/v1/vbc/patients-claim-submissions',
        VBC_DOWNLOAD_60_DAYS_PRE_POST_REPORT: '/v1/vbc/custom-reports/vertex/pre-post-report',
        VBC_HEALTH_PLAN_POLICY_REVIEW: '/v1/vbc/health-plans/policy/coeus-admin/review',
        VBC_HEALTH_PLAN_POLICY_MED_AFFAIRS_REVIEW:
            '/v1/vbc/health-plans/policy/medical-affair/review',
        VBC_HEALTH_PLAN_POLICY_CUSTOMER_REVIEW: '/v1/vbc/health-plans/policy/customer/review',
        VBC_DOWNLOAD_CLAIM_DATA_SUMMARY_REPORT:
            '/v1/vbc/custom-reports/platform/claim-data-summary',
        VBC_RULES_SET_FORM: '/v1/vbc/rule-engine/from-v2-form',
        CUSTOMER_CREATE_SYNTHETIC_VBC: '/v1/tpa/synthetic-app',
        TPA: '/v1/tpa',
        TPA_RESEND_INVITE: '/v1/tpa/{tpa_id}/resend-invite',
        TPA_SYNTHETIC_VBC: '/v1/tpa/synthetic-app',
        TPA_CLAIMS: '/v1/tpa/tpa-claims',
        TPA_CLAIM_DETAILS: '/v1/tpa/tpa-claims/{tpa_claim_id}',
        TPA_INITIAL_CLAIM_UPLOAD: '/v1/tpa/tpa-claims/initial',
        TPA_SUBSEQUENT_CLAIM_UPLOAD: '/v1/tpa/tpa-claims/subsequent',
        TPA_PATIENT_LIST: '/v1/tpa/{tpa_id}/patients',
        PERFORMANCE_REPORT: '/v1/vbc/custom-reports/performance-report',
        DRUG_FAILURE_REPORT: '/v1/tpa/drug-failure-report',
        NOTIFICATIONS_INFORMATIONAL: '/v1/notification/informational',
        NOTIFICATIONS_USER: '/v1/notification/user',
        NOTIFICATION_READ: '/v1/notification/user/read',
        NOTIFICATION_DISMISSED: '/v1/notification/user/dismissed',
        USERS_ALL: '/v1/users/all',
        MANAGE_USER_ACCESS: '/v1/users/toggle-access',
        DRUG_LABEL: '/v1/tenants/{tenant_id}/drug-labels',
        DASHBOARD_VBC_OVERVIEW: '/v1/dashboard/vbc/submissions-overview',
        DASHBOARD_PAYER_OVERVIEW: '/v1/dashboard/vbc/payer-overview',
        REBATE_ENTITY_TYPES: '/v1/entity-types',
        REBATE_CONTRACT_MANAGEMENT: '/v1/rebate/contract-management',
        REBATE_CONTRACT_ADDENDUM_UPLOAD: '/v1/rebate/contract-management/addendum-files',
        REBATE_DELETE_CONTRACT_FILE: '/v1/rebate/contract-management/{contract_id}/file',
        OPERATIONAL_INFORMATION_MANAGEMENT: '/v1/rebate/operational-information',
        INTELLIGENT_WORKSPACE_DOCUMENT_MANAGEMENT: '/v1/intelligent-workspace/document-management',
        INTELLIGENT_WORKSPACE_DOCUMENT_MANAGEMENT_CREATE_FOLDER:
            '/v1/intelligent-workspace/document-management/folder',
        INTELLIGENT_WORKSPACE_DOCUMENT_MANAGEMENT_TAGS:
            '/v1/intelligent-workspace/document-management/tags',
        INTELLIGENT_WORKSPACE_DOCUMENT_MANAGEMENT_VERSION_HISTORY:
            '/v1/intelligent-workspace/document-management/version-history',
        INVOICE_INFORMATION_MANAGEMENT: '/v1/rebate/invoice-information',
    };

    public static EIN_VALIDATION_REGEX = /^\d{2}-\d{7}$/g;
    public static EMAIL_VALIDATION_REGEX = /^\w(?:[\w.-]*\w)?@(?:\w(?:[\w-]*\w)?\.){1,}\w{2,50}$/;
    public static TEXT_VALIDATION_RULE = /^[A-Za-z]+$/i;
    public static PHONE_VALIDATION_REGEX = /^\d{3}-\d{3}-\d{4}$/g;

    public static getAPIDomain = (env: string) =>
        `https://as-coebra-platform-be-${env}.azurewebsites.net`;

    public static TOAST_AUTO_CLOSE_TIME = 3000; // milliseconds

    public static TABLE_NAMES = {
        CUSTOMERS_LIST: 'CUSTOMERS_LIST',
        USERS_LIST: 'USERS_LIST',
        ORG_GROUPS_LIST: 'ORG_GROUPS_LIST',
        INVITED_USERS_LIST: 'INVITED_USERS_LIST',
        PARTICIPANTS_LIST: 'PARTICIPANTS_LIST',
        HEALTH_PLANS_LIST: 'HEALTH_PLANS_LIST',
        HEALTH_PLANS_UPLOAD_STATUS_LIST: 'HEALTH_PLANS_UPLOAD_STATUS_LIST',
        ORGANIZATIONS_LIST: 'ORGANIZATIONS_LIST',
        BAA_REVIEW_LIST: 'BAA_REVIEW_LIST',
        PARTICIPANT_POLICIES_LIST: 'PARTICIPANT_POLICIES_LIST',
        ALL_CLAIMS_LIST: 'ALL_CLAIMS_LIST',
        CLAIM_REPORTS_LIST: 'CLAIM_REPORTS_LIST',
        OUTCOMES_REPORTS_LIST: 'OUTCOMES_REPORTS_LIST',
        DRAFT_CLAIMS_LIST: 'DRAFT_CLAIMS_LIST',
        MANAGE_APP_ACCESS: 'MANAGE_APP_ACCESS',
        VBC_CAMPAIGN_LIST: 'VBC_CAMPAIGN_LIST',
        VBC_MARKETPLACE_PAYER_OFFERS_LIST: 'VBC_MARKETPLACE_PAYER_OFFERS_LIST',
        VBC_PATIENTS_SUPPORTING_INFO: 'VBC_PATIENTS_SUPPORTING_INFO',
        ALL_TPAS_LIST: 'ALL_TPAS_LIST',
        SYNTHETIC_VBC_LIST: 'SYNTHETIC_VBC_LIST',
        TPA_CLAIMS_LIST: 'TPA_CLAIMS_LIST',
        TPA_PATIENT_LIST: 'TPA_PATIENT_LIST',
        DRUG_FAILURE_REPORT_LIST: 'DRUG_FAILURE_REPORT_LIST',
        NOTIFICATIONS_LIST: 'NOTIFICATIONS_LIST',
        ALL_CONTRACTS_LIST: 'ALL_CONTRACTS_LIST',
        ALL_OPERATIONAL_INFO_LIST: 'ALL_OPERATIONAL_INFO_LIST',
        DOCUMENT_LIST: 'DOCUMENT_LIST',
        INVOICE_INFORMATION_LIST: 'INVOICE_INFORMATION_LIST',
        DASHBOARD_PAYER_LIST: 'DASHBOARD_PAYER_LIST',
    };

    public static USER_ALL_GROUP_NAME = 'User - All';

    public static BAA_REQUIRED_LINK =
        'https://www.cms.gov/Regulations-and-Guidance/Administrative-Simplification/HIPAA-ACA/AreYouaCoveredEntity';

    public static APP_NAMES = ['VBC', 'Rebate', 'Intelligent Workspace'];

    public static LINE_OF_BUSINESS_VALUES = [
        'Commercial Only',
        'Managed Medicaid Only',
        'Commercial and Managed Medicaid',
        'Medicaid FFS',
        'Medicare',
        'Medicare Advantage',
    ];
    public static CLAIM_FILE_VERSION = {
        ONE: '1',
        TWO: '2',
        C1: 'C1',
    };

    public static PARTICIPANT_DESIGNATIONS = {
        ADM_RBT: 'Administrative Rebate',
        TRAD_RBT: 'Traditional Rebate',
        OUT_BSD_RBT: 'Outcomes-Based Rebate',
    };

    public static VBC_REPORTS_CONFIGURATION = {
        VERTEX_CUSTOM_COMBINED_REPORT: 'VERTEX_CUSTOM_COMBINED_REPORT',
        VERTEX_CUSTOM_SIXTY_DAYS_PRE_POST_REPORT: 'VERTEX_CUSTOM_SIXTY_DAYS_PRE_POST_REPORT',
        OUTCOMES_REPORT: 'OUTCOMES_REPORT',
        CLAIM_DATA_SUMMARY_REPORT: 'CLAIM_DATA_SUMMARY_REPORT',
        BBB_CUSTOM_PERFORMANCE_REPORT: 'BBB_CUSTOM_PERFORMANCE_REPORT',
    };

    public static ENVIRONMENT_NAMES = {
        DEV: 'Dev',
        SBX: 'Sandbox',
        QA: 'QA',
        BETA: 'Demo',
    };

    public static ENVIRONMENTS = {
        LOCAL: 'local',
        DEV: 'dev',
        BETA: 'beta',
        SBX: 'sbx',
        QA: 'qa',
        PROD: 'prod',
    };

    public static FEATURE_FLAGS = {
        VBC_MARKETPLACE: 'VBC_MARKETPLACE',
    };

    public static CONTRACT_TYPES = ['Volume Rebate', 'Tiered Rebate', 'Combination Rebate'];
}
